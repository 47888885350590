import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../../../../../../css/Drawdowns.css";
import styled from "styled-components";
import callApi from "../../../../../../../../../util/apiCaller";
import {
  downloadFile,
  downloadFileFromArrayBuffer,
  showNotification,
  fetchDocDetails,
} from "../../../../../../../../../util/utility";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import { getObjAndFileName } from "../../Overview/overviewUtility";
import styles from './styles/DrawdownBox.module.scss'
import { API_STATUS, COMPLETED, NOT_APPROVED, SHOW_NOTIFICATION_STATUS } from "../../../../../../../../../constants/enums";
import { DRAWDOWN_DOWNLOAD_OPTIONS, KFS_FOLDER_NAME } from "../../../../../../../../../common/ENUM";
import RepaymentEditModal from "./RepaymentEditModal";
import { getCheckIfUserHasRelevantPermissions } from "../../../../../../../../App/AppReducer";
import { useSelector } from "react-redux";
import { fetchIsComplianceStatusNotMetHelper } from "../../../../../../../../../common/ApiHelpers";
import { VERIFIED } from "../../DocumentVault/consts/consts";

const DrawdownSection = styled("div")`
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 10px;
  }
`;

const {
  downloadBox,
  option,
  section,
  investorDrawdown,
  editButton
} = styles;

export default function DrawdownBox({ investorDeals, getChildInvestorDeals,investee_organization_id }) {
  const isUserHasRepaymentsEditAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["repayments:edit"]));
  const [downloadingFileidx, setDownloadingFileidx] = useState(null);
  const [cancellingId, setCancellingId] = useState(null);
  const [downloadOptionsId, setDownloadOptionsId] = useState(null);
  const [showModal, setShowModal] = useState("NOTSHOW");
  const [verifiedDrawdowns, setVerifiedDrawdowns] = useState([]);
  const wrapperRef = useRef(null);
  const { id } = useParams();
  const getBuffer = (data) => {
    return data.file.Body.data;
  };
  const cb = () => setDownloadingFileidx(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDownloadOptionsId(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downloadOptionsId]);

  const downloadLetter = (idx, letter_url, fileType) => {
    if (isNil(letter_url)) {
      return;
    }
    setDownloadingFileidx(idx);
    const { obj, fileName } = getObjAndFileName(letter_url);
    downloadFileFromArrayBuffer(
      "deals/admin/download-from-s3",
      "post",
      obj,
      null,
      fileName,
      { type: fileType },
      getBuffer,
      cb
    );
  };

  const downloadFileMnemo = (docId) => {
    let fileName = '';
    fetchDocDetails(docId, KFS_FOLDER_NAME)
    .then(res => {
        fileName=res?.fileName;
        downloadFile({docId, fileName});
      })
    .catch( error => 
      console.error(error)
      );
  }

  const handleCancelDrawdown = (drawdown_id) => {
    setCancellingId(drawdown_id);
    callApi("admin/deals/cancel-drawdown", "post", {
      drawdown_id: drawdown_id,
      approval_status: "Not Approved",
    }).then((res) => {
      if (res && res.status === "Success") {
        getChildInvestorDeals();
        setCancellingId(null);
      } else {
        showNotification("Error", "Error cancelling drawdown");
        setCancellingId(null);
      }
    });
  };
  const DRAWDOWN_DOWNLOAD_OPTIONS = {
    DOWNLOAD_ALL: "Download All", 
    DRAWDOWN_LETTER: "Drawdown Letter",
    DRAWDOWN_CALC_SHEET: "Drawdown calc sheet",
    VENDOR_FINANCE: "Vendor Finance",
    PROFORMA_INVOICE: "Proforma invoice",
    DISBURSEMENT_SHEET: "Disbursement Sheet",
    KEY_FACT_STATEMENT: "Key Fact Statement",
    DISBURSEMENT_SHEET: "Disbursement Sheet",
    PAYMENT_SPLIT_SHEET:"Payment Split"
  }

  const downloadOptionsArr = [
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.DOWNLOAD_ALL,
    },
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.DRAWDOWN_LETTER,
    },
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.DRAWDOWN_CALC_SHEET,
    },
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.PROFORMA_INVOICE,
    },
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.DISBURSEMENT_SHEET,
    },
    {
      key: DRAWDOWN_DOWNLOAD_OPTIONS.PAYMENT_SPLIT_SHEET,
    }
  ];


  const callTriggerDownload = async (key, drawdown) => {
    switch (key) {
      case DRAWDOWN_DOWNLOAD_OPTIONS.DOWNLOAD_ALL: {
        downloadLetter(drawdown.id, drawdown?.drawdown_letter, "application/pdf");
        downloadLetter(drawdown.id, drawdown?.disbursement_sheet, "application/xlsx");
        downloadLetter(drawdown.id, drawdown?.dd_calc_sheet, "application/xlsx");
        downloadLetter(drawdown.id, drawdown?.proforma_invoice_url, "application/pdf");
        downloadLetter(drawdown.id, drawdown?.vendor_finance_letter_url, "application/pdf");
        if (drawdown?.payment_split_sheet && drawdown?.payment_split_sheet.id && drawdown?.payment_split_sheet?.fileName) {
          const docId = drawdown?.payment_split_sheet.id;
          const fileName = drawdown?.payment_split_sheet.fileName;
          downloadFile({ docId, fileName });
        }
        if(drawdown?.kfsDocId?.length > 0){
          downloadFileMnemo(drawdown?.kfsDocId);
        }
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.DRAWDOWN_LETTER: {
        const input = {
          drawdown_investor_deal_id: drawdown.investor_deal_id,
          investee_organization_id: id,
        }
        const notMetFoundRes = await fetchIsComplianceStatusNotMetHelper(input);
        if(notMetFoundRes?.data?.notMetFound) {showNotification("warning", "Condition Precedents (CPs) for the terms are not completed by the company");}
        downloadLetter(drawdown.id, drawdown?.drawdown_letter, "application/pdf");
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.PAYMENT_SPLIT_SHEET: {
        if (drawdown?.payment_split_sheet && drawdown?.payment_split_sheet.id && drawdown?.payment_split_sheet?.fileName) {
          const docId = drawdown?.payment_split_sheet.id;
          const fileName = drawdown?.payment_split_sheet.fileName;
          downloadFile({ docId, fileName });
        } else {
          showNotification(
            SHOW_NOTIFICATION_STATUS.ERROR,
            "Payment Split Sheet is not Available"
          );
        }
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.DRAWDOWN_CALC_SHEET: {
        downloadLetter(drawdown.id, drawdown?.dd_calc_sheet, "application/xlsx");
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.VENDOR_FINANCE: {
        downloadLetter(drawdown.id, drawdown.vendor_finance_letter_url, "application/pdf");
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.PROFORMA_INVOICE: {
        downloadLetter(drawdown.id, drawdown.proforma_invoice_url, "application/pdf");
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.DISBURSEMENT_SHEET: {
        downloadLetter(drawdown.id, drawdown.disbursement_sheet, "application/xlsx");
        break;
      }
      case DRAWDOWN_DOWNLOAD_OPTIONS.KEY_FACT_STATEMENT: {
        downloadFileMnemo(drawdown?.kfsDocId);
        break;
      }
      default: {
        // Handle default case if needed
        break;
      }
    }
  };

  const DownloadOptionsBox = ({ drawdown, hasDrawdownKFS }) => {

    // Show Vendor Finance only if it is available
    if(drawdown?.vendor_finance_letter_url) {
      downloadOptionsArr.splice(3, 0, {
        key: DRAWDOWN_DOWNLOAD_OPTIONS.VENDOR_FINANCE,
      })
    }

    if ( hasDrawdownKFS && !downloadOptionsArr.find(item => item.key===DRAWDOWN_DOWNLOAD_OPTIONS.KEY_FACT_STATEMENT) ){ //can plase a var isKFSRequired
      downloadOptionsArr.push({
        key: DRAWDOWN_DOWNLOAD_OPTIONS.KEY_FACT_STATEMENT
      });
    }
    return (
      <div className={downloadBox} ref={wrapperRef}>
        {downloadOptionsArr.map((item, index) => (
          <div
            className={option}
            key={index}
            onClick={() => {
              callTriggerDownload(item.key, drawdown)
            }}
          >
            {item.key}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="drawdowns-border">
      <div className="fs16 fw600 color3 mb-4">
        <span className="mr-1">Drawdowns</span>
        <span className="tooltips">
          <img className="ques-icon" src="/assets/ques-icon.svg" alt="" />
          <span
            class="tooltiptexts fw300"
            style={{ inset: "auto", marginLeft: "-68px", marginTop: "25px" }}
          >
            Lowest discount offered by investors on your contracts. This is used
            to determine your Upfront payout.
          </span>
        </span>
      </div>
      {!isEmpty(investorDeals) &&
        investorDeals.map((investorDeal, index) => (
          <DrawdownSection key={index}>
            <div>
              <div className="dflex-ja fs10 fw500 p-2">
                <div>{investorDeal.investor_name}</div>
                <div className="color6 dflex-ja w-25">
                  <span className="mr-2">{investorDeal.approved} lacs</span>
                  <span></span>
                </div>
              </div>
              <hr className="my-0"></hr>
              {!isEmpty(investorDeal.drawdowns) ? (
                investorDeal.drawdowns.map((investordrawdown, index) => (
                  <div className="dflex-ja fs10 fw500 p-2" key={index}>
                    <div className={investorDrawdown}>
                      <span className="mr-2">
                        {moment(investordrawdown.drawdown_date).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      {downloadingFileidx === investordrawdown._id ? (
                        <span id="loading-spinner">
                          <img
                            src=""
                            className="spin-icon mr-3"
                            style={{ marginRight: "8px" }}
                            alt=""
                          />
                        </span>
                      ) : (
                        <>
                          {(investordrawdown?.drawdown_letter ||
                            investordrawdown?.disbursement_sheet) && (
                               <div className={section}>
                               <div>
                               <img
                                src="/assets/threeDots.svg"
                                onClick={() => setDownloadOptionsId(investordrawdown?._id)}
                              />
                               </div>
                              {downloadOptionsId === investordrawdown?._id && <DownloadOptionsBox drawdown={investordrawdown} hasDrawdownKFS={investorDeal?.hasDrawdownKFS}/>}</div>
                          )}
                        </>
                      )}  
                    </div>
                    <div>
                      <span
                        className={`${investordrawdown.drawdown_status
                          ?.split(" ")
                          .join("-")}-bg`}
                      >
                        {verifiedDrawdowns.includes(investordrawdown._id) ? VERIFIED : investordrawdown.drawdown_status}
                      </span>
                    </div>
                    <div className="dflex-ja w-25">
                      <span className="mr-2">
                        {investordrawdown.drawdown_amount?.toFixed(2)} lacs
                      </span>
                      {investordrawdown.drawdown_status === "Open" && (
                        <span
                          onClick={() =>
                            handleCancelDrawdown(investordrawdown._id)
                          }
                        >
                          {cancellingId === investordrawdown._id ? (
                            <span id="loading-spinner">
                              <img
                                src=""
                                className="spin-icon mr-3"
                                style={{ marginRight: "8px" }}
                                alt=""
                              />
                            </span>
                          ) : (
                            <img src="/assets/clear-red.svg" alt="" />
                          )}
                        </span>
                      )}
                    </div>
                    {isUserHasRepaymentsEditAccess &&
                    ![NOT_APPROVED, COMPLETED].includes(investordrawdown?.drawdown_status ?? "") &&
                      <img className = {editButton} 
                      src="/assets/editIcon.svg" 
                      alt = "Edit repayment schedule" 
                      title="Edit repayment schedule"
                      onClick={()=>setShowModal(investordrawdown._id)}/>
                    }
                  </div>
                ))
              ) : (
                <div className="fs10 fw500 clr14 p-2">
                  <i>No Drawdowns</i>
                </div>
              )}
            </div>
          </DrawdownSection>
        ))}
         <RepaymentEditModal
            showModal={showModal} 
            setShowModal={setShowModal}
            investee_organization_id={investee_organization_id}
            setVerifiedDrawdowns={setVerifiedDrawdowns}
            />
    </div>
  );
}
