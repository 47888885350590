import React, {useState} from 'react';
import clsx from 'clsx';
import styles from './ShowButtons.module.scss';
import SaveSVG from '../../../../../../../../../../../../../../components/SVGs/SaveSVG';
import callApi from '../../../../../../../../../../../../../../util/apiCaller';
import {
    API_STATUS,
    GENERIC_ERROR,
    IDENTIFIER_TYPE,
    SHOW_NOTIFICATION
} from '../../../../../../../../../../../../../../constants/enums';
import {showNotification} from '../../../../../../../../../../../../../../util/utility';
import {useSelector} from 'react-redux';
import {getInvesteesOrganization} from '../../../../../../../../../../../../AdminReducers';
import {LIMIT_TYPES, TOT_STATUS} from '../../../../../../../../../../../../../../common/ENUM';
import {sendWaitlistedEmailHelper} from '../../../../../../../../../../../../../../common/ApiHelpers';
import { sendScaleTot } from '../../../../../../../../../../../../../../common/ApiContainer';
import moment from 'moment';

export default function ShowButtons({
                                        currentStep,
                                        path,
                                        totId,
                                        totStatus,
                                        isModerating,
                                        identifierId,
                                        status,
                                        setIsModerating,
                                        isSaveClicked,
                                        setTotData,
                                        detectChange,
                                        onCancel,
                                        onSave,
                                        fetchTotData,
                                        fetchMonthlyStatusInfo,
                                        setLastEmail,
                                        rrfTermToggle,
                                        debtSyndicationToggle,
                                        totData,
                                        isWaitListed,
                                        isExpired = false
                                    }) {
    const {EmailUserBtn, ModerateBtn, DatePickerContainer, totExpiryDatePicker, disabledDatePicker, ActiveTillDate, disabledFontStyle} = styles;
    const WAITLISTED = 'Waitlisted';
    const investeeOnboardedId = useSelector(getInvesteesOrganization)?.investee_onboarded_id;
    const investeeOrganizationId = useSelector(getInvesteesOrganization)?._id;
    const [sendingEmail, setIsSendingEmail] = useState(false);

    const emailUser = () => {
        const totMail = () => {
            callApi(`admin/tot-history/${totId}/sendTotEmail`, 'post', {sendWithoutEmail: false})
                .then((res) => {
                    if (res.status === API_STATUS.SUCCESS) {
                        showNotification(SHOW_NOTIFICATION.SUCCESS, 'TOT Email sent successfully')
                        fetchTotData();
                    } else {
                        const message = res.data?.message ?? GENERIC_ERROR;
                        showNotification(SHOW_NOTIFICATION.ERROR, message)
                    }
                })
        }
        const addOnMail = async () => {
            if (status === WAITLISTED) {
                const res = await sendWaitlistedEmailHelper(investeeOrganizationId, IDENTIFIER_TYPE.ADD_ON, identifierId)
                if (res?.responseData?.responseCode === 20) {
                    setLastEmail(moment(res?.lastEmailSentDate).format('HH:MM DD-MMM-YYYY'))
                    showNotification(SHOW_NOTIFICATION.SUCCESS, res?.responseData?.responseMessage ?? 'Email sent successfully')
                } else {
                    showNotification(SHOW_NOTIFICATION.ERROR, res?.responseData?.responseMessage ?? GENERIC_ERROR)
                }
            } else {
                callApi(`v2/investee-onboarding/${investeeOnboardedId}/email-user?path=${path}`, "get").then(res => {
                    if (res.status === API_STATUS.SUCCESS) {
                        showNotification(SHOW_NOTIFICATION.SUCCESS, "Email sent successfully");
                        fetchMonthlyStatusInfo();
                    } else {
                        const message = res.data?.message ?? GENERIC_ERROR;
                        showNotification(SHOW_NOTIFICATION.ERROR, message);
                    }
                });
            }
        }
        if (currentStep === 2 || currentStep === 3) totMail();
        else addOnMail();
    }

    const isShowingEmailButton = () => (currentStep === 1 || ((currentStep === 2 || currentStep === 3) && (totStatus === TOT_STATUS.CREATED || totStatus === TOT_STATUS.UPDATED_NOT_SENT)));

    const isModerateButtonDisabled = () => (currentStep === 1 && (isExpired || isWaitListed)) || (currentStep === 2 && ((!rrfTermToggle) || (isExpired || isWaitListed))) || (currentStep === 3 && (!debtSyndicationToggle));

    const emailScaleUser = async () => {
        try {
            const stageTotId = totData['DebtSyndication']?.stage_id;
            if (!stageTotId) {
                showNotification('error', "stage id donot exist")
                return
            }
            setIsSendingEmail(true);
            const res = await sendScaleTot(stageTotId);
            if (res.data.data === 'Tot Sent') showNotification('success', 'Email sent successfully');
            else showNotification('error', "Signed EL already uploaded please remove it")
        } catch (err) {
            showNotification('error', "Signed EL already uploaded please remove it")
        } finally {
            setIsSendingEmail(false)
        }

    }
    if (isModerating && !((currentStep === 1 || currentStep === 2) && (isExpired || isWaitListed))) {
        return (
            <div style={currentStep === 2 ? {
                width: '40rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            } : {}}>
                {currentStep === 2 && (
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p style={{fontSize: '0.625rem', fontFamily: 'Gilroy-Semibold,serif', margin: 0}}>Terms Active
                            Till Date:</p>
                        <input
                            type={"date"}
                            className={styles.totExpiryDatePicker}
                            value={totData?.[LIMIT_TYPES.RECUR_LIMIT]?.tot_expiry_date ? moment(totData?.[LIMIT_TYPES.RECUR_LIMIT].tot_expiry_date).format("YYYY-MM-DD") : moment().add(30, 'days').format("YYYY-MM-DD")}
                            onFocus={e => e.target.showPicker()}
                            onChange={e => {
                                const obj = {...totData};
                                obj[LIMIT_TYPES.RECUR_LIMIT].tot_expiry_date = new Date(e.target.value).toISOString();
                                if (obj[LIMIT_TYPES.STANDARD]) obj[LIMIT_TYPES.STANDARD].tot_expiry_date = new Date(e.target.value).toISOString();
                                if (obj[LIMIT_TYPES.MAX]) obj[LIMIT_TYPES.MAX].tot_expiry_date = new Date(e.target.value).toISOString();
                                setTotData(obj);
                                detectChange[LIMIT_TYPES.RECUR_LIMIT] = true;
                            }}
                        />
                    </div>
                )}
                <div style={{width: '15rem', display: "flex", alignItems: 'center', gap: '0.5rem'}}>
                    <button
                        className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center"
                        onClick={onCancel}
                    >
                        <img
                            src="../../../../../assets/icon-clear.svg"
                            alt="cross"
                            className="mr-2"
                            style={{width: '11px'}}
                        />
                        <span>Cancel</span>
                    </button>
                    <button
                        className="save-btn btn5 btnht1 d-flex align-items-center justify-content-center"
                        onClick={onSave}
                        disabled={isSaveClicked}
                    >
                        <SaveSVG/>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        );
    }
    return (
        <>
            {isShowingEmailButton() && (
                <>                
                    {currentStep === 2 && (
                        <div className={DatePickerContainer}>                        
                            <p className={clsx([
                                    ActiveTillDate,
                                    {
                                        [disabledFontStyle]: (isExpired || isWaitListed),
                                    },
                            ])}>
                                Terms Active Till Date:
                            </p>
                            <input
                                type={"date"}
                                disabled={(currentStep === 2 && ((!rrfTermToggle) || isExpired || isWaitListed))}   
                                className={clsx([
                                    totExpiryDatePicker,
                                    {
                                        [disabledDatePicker]: (isExpired || isWaitListed),
                                    },
                                ])}
                                value={totData?.[LIMIT_TYPES.RECUR_LIMIT]?.tot_expiry_date ? moment(totData?.[LIMIT_TYPES.RECUR_LIMIT].tot_expiry_date).format("YYYY-MM-DD") : moment().add(30, 'days').format("YYYY-MM-DD")}
                                onFocus={e => e.target.showPicker()}
                                onChange={e => {
                                    const obj = {...totData};
                                    obj[LIMIT_TYPES.RECUR_LIMIT].tot_expiry_date = new Date(e.target.value).toISOString();
                                    if (obj[LIMIT_TYPES.STANDARD]) obj[LIMIT_TYPES.STANDARD].tot_expiry_date = new Date(e.target.value).toISOString();
                                    if (obj[LIMIT_TYPES.MAX]) obj[LIMIT_TYPES.MAX].tot_expiry_date = new Date(e.target.value).toISOString();
                                    setTotData(obj);
                                    detectChange[LIMIT_TYPES.RECUR_LIMIT] = true;
                                }}
                            />
                        </div>
                    )}
                    <button className={EmailUserBtn} onClick={emailUser} disabled={isExpired || isWaitListed}>
                        <img src="/assets/email.svg" alt="Email"/>
                        <span>Email User</span>
                    </button>
                </>
            )}
            {currentStep === 3 &&
                <button className={EmailUserBtn} onClick={emailScaleUser} disabled={sendingEmail}>
                    <img src="/assets/email.svg" alt="Email"/>
                    <span>Email Scale DS Terms</span>
                </button>
            }
            <button className={ModerateBtn}
                disabled={ isModerateButtonDisabled()}
                onClick={() => setIsModerating(true)}
            >
                <img className="mr-1" src="/assets/moderate-btn-icon.svg" alt="Moderate"/>{' '}
                <span>Moderate</span>
            </button>
        </>
    );
}

