import React, {Component} from "react";
import ProtectedAdminRoute from "../ProtectedAdminRoute";
import {Switch, Redirect} from "react-router-dom";

import CompaniesViewPage from "../modules/Admin/pages/CompaniesViewPage/CompaniesViewPage";
import CompaniesPage from "../modules/Admin/pages/CompaniesPage/CompaniesPage";
import ContractsPage from "../modules/Admin/pages/ContractsPage/ContractsPage";
import InvestorsPage from "../modules/Admin/pages/InvestorsPage/InvestorsPage";
import DealsViewPage from "../modules/Admin/pages/CompaniesPage/components/DealsViewPage/DealsViewPage";
import TradesPage from "../modules/Admin/pages/TradesPage/TradesPage";
import HelpRequestPage from "../modules/Admin/pages/HelpRequestPage/HelpRequestPage";
import EmailTemplateList from "../modules/Content/EmailTemplateList/EmailTemplateList";
import PdfTemplateList from "../modules/Content/PdfTemplateList/PdfTemplateList";
import SiteContentList from "../modules/Content/SiteContentList/SiteContentList";
import ClubAccessPage from "../modules/Admin/pages/ClubAccessPage/ClubAccessPage";
import RepaymentsPage from "../modules/Admin/pages/RepaymentsPage/RepaymentsPage";
import ContractDetailPage from "../modules/Admin/pages/ContractsPage/components/ContractDetailPage";
import InvestorDetailPage from "../modules/Admin/pages/InvestorDetailsPage/components/InvestorDetailView";
import InvestorViewPage from "../modules/Admin/pages/InvestorDetailsPage/InvestorViewPage";
import WithdrawalsPage from "../modules/Admin/pages/WithdarwalPage/WithdrawalsPage";
import SwapsPage from "../modules/Admin/pages/SwapsPage/SwapsPage";
import AutoInvestPage from "../modules/Admin/pages/AutoInvestPage/AutoInvestPage";
import InvoicesPage from "../modules/Admin/pages/InvoicesPage/InvoicesPage";
import NachCollectionsPage from "../modules/Admin/pages/NachCollectionsPage/NachCollectionsPage";
import OrderRepaymentsPage from "../modules/Admin/pages/OrderRepaymentsPage/OrderRepaymentsPage";
import RecurCheckoutPage from "../modules/Admin/pages/RecurCheckoutPage/RecurCheckoutPage";
import Tds from "../modules/Admin/pages/Tds/Tds";
import CustomerRequests from "../modules/Admin/pages/CustomerRequestsPage/CustomerRequests";
import RecordedPayments from "../modules/Admin/pages/RecordedPaymentsPage/RecordedPayments";

class Routes extends Component {
    render() {
        return (
            <div style={{margin: '0px', minHeight: '100vh'}}>
               <Switch>
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/investor/view"
                        component={(props) => (
                            <InvestorDetailPage
                                {...props}
                            />
                        )}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        path="/admin/company/:id/view/:type?"
                        component={CompaniesViewPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/investor/:id/view"
                        component={InvestorViewPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/company/list"
                        component={CompaniesPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/checkout"
                        component={RecurCheckoutPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/repayments/list"
                        component={(props) => (
                            <RepaymentsPage
                                {...props}
                            />
                        )}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/orderRepayments/list"
                        component={OrderRepaymentsPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/contracts/list"
                        component={ContractsPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/contracts/contractdetailpage"
                        component={(props) => (
                            <ContractDetailPage
                                {...props}
                            />
                        )}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/investors/list"
                        component={InvestorsPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/company/list?tab=deals"
                        // component={DealsViewPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/autoinvest/list"
                        component={AutoInvestPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/trades/list"
                        component={TradesPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/invoices/list"
                        component={InvoicesPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/customer-requests/list"
                        component={CustomerRequests}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/clubaccess/list"
                        component={ClubAccessPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/platformhelp/list"
                        component={HelpRequestPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/emailtemplates/list"
                        component={(props) => (
                            <EmailTemplateList
                                {...props}
                            />
                        )}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/pdftemplates/list"
                        component={(props) => (
                            <PdfTemplateList
                                {...props}
                            />
                        )}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/content/list"
                        component={(props) => (
                            <SiteContentList
                                {...props}
                            />
                        )}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/withdrawal/list"
                        component={WithdrawalsPage}
                    />
                    <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/nachcollections/list"
                        component={NachCollectionsPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/swaps/list"
                        component={SwapsPage}
                    />
                     <ProtectedAdminRoute
                        isUserAdmin={this.props.isUserAdmin}
                        isAuthenticated={this.props.isAuthenticated}
                        exact path="/admin/recordedPayments/list"
                        component={RecordedPayments}
                    />
                    <Redirect exact from="/admin" to="/admin/company/list"/>
                </Switch>
            </div>
        );
    }
}

export default Routes;
