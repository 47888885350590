import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { DATA_TYPE } from "../../../../../../../../../../../common/ENUM";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { capitalizeFirstLetter } from "../../../../../../../../../../../util/utility";
import ReactTooltip from 'react-tooltip';

import styles from "../../metrics.module.css";
import { getActiveMetricsList } from "../../../../../../../../../../../common/ApiHelpers";

const CompanyMetricsView = ({
  isReadonly,
  setIsReadonly,
  investeeOrganization,
  id,
  year,
  quarter,
  setReload,
  setFirstRender,
  setDisableUpdate,
  notActiveTabClicked,
  setNotActiveTabClicked,
  formatDateSlice,
  createdAtTime,
  jsDate,
  lastUpdate,
  activeType,
  activeClicked,
  setMakeActiveClicked,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  setEditSave,
  disable_date,
  set_disable_date,
  reRenderId,
  setReRenderId,
  computeType,
}) => {
  const [companyResp, setCompanyResp] = useState([]);
  const [inputVal, setInputVal] = useState({});
  const [post_arr, set_post_arr] = useState([]);
  const [edit, setEdit] = useState(false);
  const [cancelObj, setCancelObj] = useState({});
  const [tooltipContent, setTooltipContent] = useState(null); 
  const postObjRef = useRef(null);

  const headers = [
    "PnL Metrics",
    "BS Metrics",
    "Cashflow Metrics",
    "Equity Metrics",
    "Track Record",
    "Business Metrics"
  ];

  const handleTooltip = (filteredObj) => {
    if (!!filteredObj &&
      !!filteredObj.formula &&
      filteredObj.formula !== "")
      setTooltipContent(filteredObj);
  };

  const clearTooltip = () => {
    setTooltipContent(null);
  }; 
  const HandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputVal({ ...inputVal, [name]: value });
    const tempPostObj = { ...post_arr };
    const tempArr = [ ...tempPostObj.metrics ];
    let doesObjAlreadyExist = null;
    let index = 0;
    for (let obj of tempArr) {
      if (obj.name === name) {
        doesObjAlreadyExist = true;
        break;
      }
      index += 1;
    }
    if (doesObjAlreadyExist) tempArr[index].value = value;
    else tempArr.push({ name, value });
    tempPostObj.metrics = tempArr;
    set_post_arr(tempPostObj);
  };
  const HandleSave = () => {
    setMakeActiveClicked(false);
    setNotActiveTabClicked(true);
    setIsReadonly(true);
    setFirstRender(false);
    const type = year ? "yearly" : "quarter";
    const metrics_type = edit ? "update-metrics" : "add-metrics";
    const body = edit ? { ...post_arr } : { ...post_arr, metrics: postObjRef.current };
    const PostCalling = async () => {
      await callApi(
        `metrics/${metrics_type}?investeeOrgId=${investeeOrganization._id}&organisationMetricId=${id}&type=${type}`,
        "post",
        body,
        undefined,
        undefined,
        true
      );
      if (edit) {
        setEditSave(true);
      } else {
        setEditSave(false);
      }
      setReload(true);
      setEdit(false);
    };
    PostCalling();
  };
  const AfterSave = () => {
    setIsReadonly(true);
    return (
      <div className={styles.EditActiveBtn}>
        <button
          className={styles.EditBtn}
          disabled={computeType==="cem"}
          onClick={() => {
            setNotActiveTabClicked(false);
            setIsReadonly(false);
            setEdit(true);
            set_disable_date(true);
          }}
        >
          Edit Metrics
        </button>
        <button
          className={styles.ActiveBtn}
          onClick={() => {
            const type = year ? "yearly" : "quarter";
            const PutCalling = async () => {
              await callApi(
                `metrics/set-active/${type}?investeeOrgId=${investeeOrganization._id}&organisationMetricId=${id}`,
                "put",
                undefined,
                undefined,
                undefined,
                true
              );
              setMakeActiveClicked(true);
              setReload(true);
            };
            PutCalling();
          }}
        >
          Make Active
        </button>
      </div>
    );
  };
  const SaveCancel = () => {
    return (
      <div className={styles.SaveCancelBtn}>
        <button
          className={styles.SaveBtn}
          style={edit && isEmpty(post_arr.metrics) ? { cursor: 'not-allowed' } : null}
          onClick={HandleSave}
          disabled={edit && isEmpty(post_arr.metrics)}
        >
          Save
        </button>
        <button
          className={styles.CancelBtn}
          onClick={() => {
            setEdit(false);
            setIsReadonly(true);
            setDisableUpdate(false);
            setInputVal(cancelObj);
            if (activeClicked) setNotActiveTabClicked(false);
            else setNotActiveTabClicked(true);
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  useEffect(() => {
    const final_arr = {...post_arr};
    final_arr.fromDate = fromDate;
    final_arr.toDate = toDate;
    set_post_arr(final_arr);
  }, [fromDate, toDate]);

  useEffect(() => {
    const test = async () => {
      if(!id) return;
      const data = await getActiveMetricsList(investeeOrganization._id, id, quarter);
      const dummy_obj = {};
      const temp_arr = [];
      for (let obj of data) {
        dummy_obj[obj.name] = obj.value;
        // If Update button is clicked, we need this array
        let temp_obj = {};
        temp_obj["name"] = obj.name;
        temp_obj["value"] = obj.value;
        temp_obj["type"] = obj.type;
        temp_arr.push(temp_obj);
        postObjRef.current = temp_arr;
      }
      const final_arr = {
        fromDate: fromDate,
        toDate: toDate,
        metrics: [],
      };
      set_post_arr(final_arr);
      setInputVal(dummy_obj);
      setCancelObj(dummy_obj);
      setCompanyResp(data);
      setReRenderId(false);
    };
    test();
  }, [id, quarter, reRenderId]);

  const getClass = (isEdited) => {
    if (isEdited) return styles.main_input_blue;
    return styles.main_input;
  }

  return (
    <section className={styles.company_metrics} id={styles.company_metrics}>

      <div className={styles.containerForComputeType}>
      <div className={styles.comp_metrics_word}>Company Metrics</div>
      <div  className={styles.comp_metrics_container}>
          <div>
              <span className={styles.requiredText}>(</span>
              <span className={styles.star}>*</span>
              <span className={styles.requiredText}>) Required for Scale</span>
            </div>
            {computeType ? (
              <div className={styles.computeType} id={styles.computeType}>
                <span id={styles.dot}></span> {capitalizeFirstLetter(computeType)}
              </div>
            ) : (
              <div className={styles.computeType} id={styles.computeType}>
                <span id={styles.dot}></span> Invoices
              </div>
            )}

      </div>

       
      </div>
      <div className={styles.company_metrics_row}>
        {quarter && activeType === DATA_TYPE.QUARTER ? (
          <div className={styles.activeType}>
            {" "}
            <span className={styles.dot}></span> Active
          </div>
        ) : year && activeType === DATA_TYPE.YEAR ? (
          <div className={styles.activeType}>
            {" "}
            <span className={styles.dot}></span> Active
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.metrics_table}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={styles.container}>
            {headers.map((head) => {
              return (
                <div
                  className={styles.component}
                  id={styles.col_input}
                  key={head}
                >
                  {companyResp &&
                    companyResp.filter(
                      (obj) => obj["metrics_type.name"] === head
                    ).length > 0 && (
                      <div className={styles.headers}>{head}</div>
                    )}
                  {companyResp &&
                    companyResp
                      .filter((obj) => obj["metrics_type.name"] === head)
                      .map((filteredObj) => (
                        <div className={styles.input_row} key={filteredObj.id}>
                          <div
                            className={
                              styles.input_rectangle +
                              " " +
                              (!isReadonly ? styles.input_rectangle_edit : "")
                            }
                            onMouseEnter={() =>
                              handleTooltip(filteredObj)
                            }
                            onMouseLeave={clearTooltip}
                            id={styles.input_rectangle}
                          >
                            <label
                              htmlFor={filteredObj.name}
                              id={styles.input_label}
                              data-tip=""
                              data-for={`metric-${filteredObj.id}`}
                            >
                              {filteredObj.name}
                              {filteredObj?.requiredForScale && (
                                <span
                                  style={{ color: "red"}}
                                >
                                  *
                                </span>
                              )}
                            </label>
                            {!!tooltipContent && (
                              <ReactTooltip
                                id={`metric-${filteredObj.id}`}
                                effect="solid"
                                className={styles.tooltip_class}
                                place="bottom"
                                overridePosition={false}
                                backgroundColor="white"
                                textColor="black"
                                type="light"
                                globalEventOff="click"
                                delayShow={100}
                                delayHide={200}
                                clickable={true}
                              >
                                <div
                                  className={styles.tooltip_component}
                                >
                                  <span className={styles.tooltip_span}>
                                    {tooltipContent.name}
                                  </span>
                                  <span style={{ display: "inline" }}>
                                    {" = "}
                                    {tooltipContent.formula}
                                  </span>
                                </div>
                              </ReactTooltip>
                            )}

                            <div className={styles.input_div}>
                              <input
                                type="text"
                                pattern="[0-9.,%]+"
                                id={filteredObj.name}
                                name={filteredObj.name}
                                value={inputVal[filteredObj.name]}
                                className={
                                  getClass(filteredObj.isEdited) +
                                  " " +
                                  (!isReadonly ? styles.main_input_edit : "")
                                }
                                onChange={HandleChange}
                                readOnly={isReadonly}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              );
            })}
          </div>
          <div className={styles.last_update_details}>
        <div className={styles.update_date_block}>
        <div>
         <section className={styles.update_date}>
          <div className={styles.col_1_date}>
            <div className={styles.from}>From</div>
            <div>
              <input
                type="date"
                className={styles.col_1_date_input}
                id={styles.col_1_date_input}
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                disabled={isReadonly || disable_date}
                required
              />
            </div>
          </div>
          <div className={styles.col_2_date}>
            <div className={styles.to}>To</div>
            <div>
              <input
                type="date"
                className={styles.col_2_date_input}
                id={styles.col_2_date_input}
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                disabled={isReadonly || disable_date}
                required
              />
            </div>
          </div>
        </section>
        </div>
        <div className={styles.last_update_time}>
          <span className={styles.last_update_span}>Last Update:</span>{" "}
          {lastUpdate && createdAtTime(lastUpdate)},{" "}
          {lastUpdate && jsDate(formatDateSlice(lastUpdate))}
        </div>

        </div> 
        {!isReadonly && <SaveCancel />}
        {notActiveTabClicked && <AfterSave />}
      </div>
        </form>
      </div>
    </section>
  );
};

export default CompanyMetricsView;
