import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './CompaniesTableRow.module.scss'
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getCheckIfUserHasRelevantPermissions } from "../../../../App/AppReducer";
import { updateLastModerated } from "../../../../../util/commonApi";
import { setIsAddOnLimitRequested } from "../../../../App/AppActions";
import ctaArrow from '../../../../../assets/chevron-right.svg'
import timerImg from '../../../../../assets/timer.svg'
import { queryParams, tabsEnum } from "../CompaniesPage";
import { commentTypeEnum } from "../../../../../common/ENUM";
import ModeratorNotes from "../../../../../components/ModeratorNotes/ModeratorNotes";
import ModeratorDetails from "../../CompaniesViewPage/components/CompaniesDetailPage/components/DataVault/Overview/components/ModerateBox/components/MultipleTotComponents/ModeratorDetails";
import { uw_signal_types } from "../../../../../constants/enums";

export const underwritingStatusType = {
  NOT_STARTED: "Not Started",
  ONGOING: "Ongoing",
  APPROVED: "Approved",
  NEED_INFO: "Need Info",
  WAITLISTED: "Waitlisted",
  NOT_APPROVED: "Not Approved"
}

function CompaniesTableRow({ item, ...props }) {


  const riskFlagType = {
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low",
    UPSELL: "Upsell"
  }

  const [countDownTime, setCountDownTime] = useState();
  const [timeLeftPerc, setTimeLeftPerc] = useState();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const commentBoxEle = useRef(null);

  const dispatch = useDispatch();
  const observer = useRef();
  const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));
  const isUserHasEditAccess = useSelector(state => getCheckIfUserHasRelevantPermissions(state, ["app:edit"]));
  const lastInvesteeRef = useCallback(
    (node) => {
      if (props.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore()) props.loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [props.loader, props.hasMore()]
  );
  
  const history = useHistory();

  const {
    addOn,
    debtSyndication,
    rrf,
    company_link,
    s_moderation,
    uws_notstarted,
    uws_approved,
    uws_needinfo,
    uws_ongoing,
    uws_waitlisted,
    uws_notapproved,
    UWStatus,
    red,
    green,
    yellow,
    blue,
    violet,
    grey,
    riskFlag,
    uwFlagStyles,
    cta,
    tableBox,
    commentsBox,
    commentRow,
    lastModerated
  } = styles;

  const {
    _id,
    display_name,
    name,
    status: company_status,
    is_company_approved,
    Investee_Onboarding: onboarding,
    Investee_KYC: kyc,
    Investee_Monthly_Data: monthlyData,
    is_limit_add_on_requested,
    repayment_risk,
    underWriterName,
    underWritingStatus,
    companyStatus,
    moderationAction,
    lastModeratedAt,
    dataCoverage,
    deal_priority_score,
    lastUploadedAt,
    comments,
    timer_start_date: submitDateUTC,
    onboardingId,
    addonId,
    uwFlag,
    debtSyndicationToggle,
    rrfToggle
  } = item;

  const timeDiffPerc = () => {
    const submitDateIST = new Date(submitDateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    let submissionDate = submitDateIST;
    const orgSubDate = submitDateIST;
    let currDate = new Date();
    const twoDaysTimeInSeconds = 2 * 24 * 60 * 60;
    const currDay = currDate.getDay();
    const submissionDay = submissionDate.getDay();
    const setDateToFriday = (date, daysToSubtract) => {
      date.setDate(date.getDate() - daysToSubtract);
      return new Date(`${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth()+1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}T23:59:00`);
    }
    const setDateToMonday = (date, daysToAdd) => {
      date.setDate(date.getDate() + daysToAdd);
      return new Date(`${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth()+1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}T00:00:00`);
    }
    const getTimeDiffInSeconds = (date1, date2) => {
      if(date1 < date2) return 0;
      return Math.abs(date1.getTime() - date2.getTime())/1000;
    }
    if((submissionDay === 6 || submissionDay === 0) && (currDay === 6 || currDay === 0) && getTimeDiffInSeconds(currDate, submissionDate) < twoDaysTimeInSeconds) {
      return 0;
    }
    if(submissionDay === 6) {
      submissionDate = setDateToMonday(submissionDate, 2);
    }
    else if(submissionDay === 0) {
      submissionDate = setDateToMonday(submissionDate, 1);
    }
    if(currDay === 6) {
      currDate = setDateToFriday(currDate, 1);
    }
    else if(currDay === 0) {
      currDate = setDateToFriday(currDate, 2);
    }
    const getTimeElapsedPerc = (fridayGap, mondayGap) => {
      const fridayDate = setDateToFriday(new Date(), fridayGap);
      const mondayDate = setDateToMonday(new Date(), mondayGap);
      const totalTimeDiff = (getTimeDiffInSeconds(fridayDate, submissionDate) + getTimeDiffInSeconds(currDate, mondayDate));
      if(totalTimeDiff > twoDaysTimeInSeconds) return 100;
      return (totalTimeDiff/twoDaysTimeInSeconds) * 100;
    }
    if(currDay === 1 && orgSubDate.getDay() !== 1) {
      return getTimeElapsedPerc(3, 0);
    }
    else if(currDay === 2 && (orgSubDate.getDay() !== 1 && orgSubDate.getDay() !== 2 )) {
      return getTimeElapsedPerc(4, -1);
    }
    if(getTimeDiffInSeconds(currDate, submissionDate) > twoDaysTimeInSeconds) return 100;
    const timeElapsedPerc = (getTimeDiffInSeconds(currDate, submissionDate) /twoDaysTimeInSeconds) * 100;
    return timeElapsedPerc;
  }

  const renderTimeLeft = () => {
      return (
        <div style={{ width: 16, height: 16, display: "inline-block"}}>
          <CircularProgressbar value={timeDiffPerc()} strokeWidth={20}
            styles={buildStyles({

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 1,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              pathColor: '#f4f4f4',
              textColor: '#f88',
              trailColor: '#4CB71A',
              backgroundColor: '#4CB71A',
            })} />
        </div>
      );
    // }
  }

  const handleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const stateObj = {
      openDataVaultTab: true,
    };
    const payload = {};
    if(is_limit_add_on_requested) payload.is_limit_add_on_requested = true;
    updateLastModerated(payload, item?._id);
    if (moderationAction === "START" && !is_limit_add_on_requested) {
      stateObj.openDataVaultTab = false;
    }
    dispatch(setIsAddOnLimitRequested({[_id]: true}));
    history.push({
      pathname: `/admin/company/${_id}/view`,
      state: stateObj,
    });
  };

  const redirectToApprovedLimits = (e, companyName) => {
    e.preventDefault();
    e.stopPropagation();
    const stateObj = {
      searchCompanyName: companyName
    }
    history.push({
      pathname: `/admin/company/list`,
      search: `?tab=${queryParams.APPROVED_LIMITS}&show-hide-expired-filter=${props.hideCompletedExpired}`,
      state: stateObj,
    });
    props.setCurrentTab(tabsEnum.APPROVED_LIMITS);
  }

  const getUWStatusClassName = () => {
      if(underWritingStatus === underwritingStatusType.NOT_STARTED) return uws_notstarted;
      if(underWritingStatus === underwritingStatusType.ONGOING) return uws_ongoing;
      if(underWritingStatus === underwritingStatusType.APPROVED) return uws_approved;
      if(underWritingStatus === underwritingStatusType.NEED_INFO) return uws_needinfo;
      if(underWritingStatus === underwritingStatusType.WAITLISTED) return uws_waitlisted;
      if(underWritingStatus === underwritingStatusType.NOT_APPROVED) return uws_notapproved;
  }
  
  const getRiskFlag = () => {
    if(repayment_risk === riskFlagType.HIGH) return red;
    if(repayment_risk === riskFlagType.MEDIUM) return yellow;
    if(repayment_risk === riskFlagType.LOW) return green;
    if(repayment_risk === riskFlagType.UPSELL) return blue;
  }

  const getUwFlag = () => {
    if(uwFlag === uw_signal_types.FANTASTIC) return violet;
    if(uwFlag === uw_signal_types.GOOD) return green;
    if(uwFlag === uw_signal_types.SPARK) return grey;
    if(uwFlag === uw_signal_types.DIFFICULT ) return yellow;
    if(uwFlag === uw_signal_types.REJECT ) return red;
  }

  const positionStyle = () => {
    if(moderationAction && (isUserHasUWViewAccess || isUserHasEditAccess) && lastModeratedAt) {
      return {
        verticalAlign: "bottom"
      }
    }
  }

  const onCommentBoxClick = (e) => { 
    e.stopPropagation();
    e.preventDefault();
    setShowCommentModal(true);
  }
  const renderer = (
    <>
      <div className={`${cta} d-flex`} onClick={(e) => redirectToApprovedLimits(e, display_name ?? name)}>
      <td className={`d-flex ${tableBox}`} onClick={e => e.stopPropagation()} >
      <Link className="link_anchor w-100" to={`/admin/company/${item._id}/view`}>
        <div className="d-flex">
          <div className={`${uwFlagStyles} ${getUwFlag()}`}></div>
          <div
            title={display_name ? display_name : name}
            className={`text-truncate ${company_link}`}
            style={{ maxWidth: "115px", paddingRight: "0px" }}
          >
            {display_name ? display_name : name}
            </div>
        {!!is_limit_add_on_requested && <div className={addOn}>ADD</div>}
        {!!debtSyndicationToggle && <div className={debtSyndication}>DS</div>}
        {!!rrfToggle && <div className={rrf}>RRF</div>}
        </div>
        </Link>
      </td>
      <td> {companyStatus ?? "-"}</td>
      <td>{`${dataCoverage} %` ?? "-"}</td>
      <td>{deal_priority_score ? `${deal_priority_score}` : "-"}</td>
      <td>{underWriterName ?? "-"}</td>
      <td className="company-status">
        <span className={`${UWStatus} ${getUWStatusClassName()}`}>
          {underWritingStatus ?? "-"}
        </span>
      </td>
      <td>
        {submitDateUTC && renderTimeLeft()} {lastUploadedAt ?? "-"}
      </td>
      <td style={positionStyle()}>
       <div>
          {moderationAction &&
          (isUserHasUWViewAccess || isUserHasEditAccess) ? (
            <button className={s_moderation} onClick={handleAction}>
              {moderationAction}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="mt-1">
          {lastModeratedAt ? (
            <div className={lastModerated}>
              <img src={timerImg}></img>
              <div>{dayjs(lastModeratedAt).format("DD MMM YYYY")}</div>
            </div>
          ) : (
            "-"
          )}
        </div>
      </td>
        <td className="comment_box_wrapper" onDoubleClick={onCommentBoxClick} ref={(e) => e = commentBoxEle} onClick={e => e.stopPropagation()}>
        <div className={commentsBox}>
          {comments?.map((item, index) => (
            <div className="mb-2 fs10" key={display_name+index}>
              <div className={commentRow}>
                <span>
                  {dayjs(item?.created_at).format("h:mm A")},&nbsp;
                  {dayjs(item?.created_at).format("DD MMM YY")}&nbsp;
                </span>
                <span>
                  &nbsp;by {item?.owner}&nbsp;
                  {item?.purpose &&
                  item?.purpose !== commentTypeEnum.COMMENT_ONLY.key
                    ? `(${commentTypeEnum[item?.purpose].value})`
                    : ""}
                </span>
              </div>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {item?.comment.trim()}
              </div>
            </div>
          ))}
        </div>
      </td>
      <td>
        <span
          className={cta}
        >
          <img src={ctaArrow}></img>
        </span>
      </td>
    </div>
      <ModeratorNotes
        showModal={showCommentModal}
        key={item._id}
        setShowModal={setShowCommentModal}
      >
        <ModeratorDetails
          identifierType={!is_limit_add_on_requested ? "tot_onboarding" : 'tot_add_on'}
          investeeOnboardedId={onboardingId}
          addOnId={addonId}
          investeeOrgId={_id}
          underWriterName={underWriterName}
          fromMainScreen={true}
        />
      </ModeratorNotes>
      </>
  );

  if (props.dataLength === props.index + 1) {
    return <tr ref={lastInvesteeRef}>{renderer}</tr>;
  } 
  if(props.index === 0) {
    return <tr ref={props.firstRow}>{renderer}</tr>;
  }
  else {
    return <tr>{renderer}</tr>;
  }
}

export default CompaniesTableRow = React.memo(CompaniesTableRow);
