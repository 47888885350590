import React, { useCallback, useEffect, useRef, useState } from "react";
import { showNotification } from "../../util/utility";
import {
  ENVIRONMENTS,
  SHOW_NOTIFICATION,
} from "../../constants/enums";
import styles from "./CopyToClipBoard.module.scss";
import { debounce } from "lodash";

const DEFAULT_DELAY=500;

interface CopyToClipboardButtonProps {
  text?: string;
  message?: string;
  delay?: number;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  text,
  message,
  delay = DEFAULT_DELAY,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);
  const debouncedCopyToClipboard = useRef<ReturnType<typeof debounce>|undefined>(undefined);

  const copyToClipboard = useCallback(() => {
    if (!navigator.clipboard) {
      showNotification(
        SHOW_NOTIFICATION.ERROR,
        "Clipboard API not supported in your browser"
      );
      return;
    }
    setIsLoading(true);
    navigator.clipboard
      .writeText(text ?? "")
      .then(() => {
        if (!isMounted.current) return;
        showNotification(
          SHOW_NOTIFICATION.SUCCESS,
          message ?? "Copied to clipboard"
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (!isMounted.current) return;
        if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT || process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.STAGING) {
          console.error("Failed to copy: ", err);
        }
        showNotification(
          SHOW_NOTIFICATION.ERROR,
          "Failed to copy to clipboard"
        );
        setIsLoading(false);
      });
  }, [text, message]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    debouncedCopyToClipboard.current = debounce(copyToClipboard, delay);
    return () => {
      debouncedCopyToClipboard.current?.cancel();
    };
  }, [copyToClipboard]);

  return (
    <button
      onClick={()=>debouncedCopyToClipboard.current?.()}
      className={styles.copyToClipboardBtn}
      disabled={isLoading}
      aria-label={"Copy to clipboard"}
      title={"Copy to clipboard"}
      type="button"
    >
      <img
        className={styles.copyToClipboardImage}
        src="/assets/clipboard.svg"
        alt="Copy to clipboard button"
        aria-hidden="true"
      />
    </button>
  );
};

export default CopyToClipboardButton;
