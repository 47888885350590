import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { LIMIT_TYPES, ONBOARDING_STATUS, TOT_STATUS } from '../../../../../../../../../../../../common/ENUM';
import Stepper from '../../../../../../../../../../../../components/Stepper/Stepper';
import { API_STATUS, CASHFREE_SPLIT_KEY, DIRECT_COLLECTION_KEY, GENERIC_ERROR, PREPARE_TERMS_TAB, RAZORPAY_SPLIT_KEY, SHOW_NOTIFICATION ,MODERATION, APPLICATION_TYPE, GST_INACTIVE, IDENTIFIER_TYPE} from '../../../../../../../../../../../../constants/enums';
import '../../../../../../../../../../../../css/ModerationComment.css';
import callApi from '../../../../../../../../../../../../util/apiCaller';
import { getTranchTenure, showNotification,formatDate, getRoiFromIrr } from '../../../../../../../../../../../../util/utility';
import { fetchInvesteeDetails,fetchToggleInfo,fetchDealReport } from '../../../../../../../../../../AdminActions';
import { getInvesteesOrganization,getInvesteeOnboardingData,getGstAnnualRev,getRrfToggleDetails,getDebtSyndicationToggleDetails } from '../../../../../../../../../../AdminReducers';
import DataVaultUnderwriting from '../../DataVaultUnderwriting';
import AdditionalInfo from '../components/AdditionalInfo';
import ModeratorDetails from '../components/MultipleTotComponents/ModeratorDetails';
import MultipleTotFooter from '../components/MultipleTotComponents/MultipleTotFooter';
import PrepareTerms from '../components/MultipleTotComponents/PrepareTerms';
import ReviewFinancialData from '../components/MultipleTotComponents/ReviewFinancialData';
import DetailsInput from '../DetailsInput';
import RenderSingleCategory from './components/RenderSingleCategory/RenderSingleCategory';
import RenderStatus from './components/RenderStatus/RenderStatus';
import RenderTextBox from './components/RenderTextBox/RenderTextBox';
import ShowButtons from './components/ShowButtons/ShowButtons';
import DealEvaluation from '../components/DealEvaluation';
import StepperTabs from '../../../../../../../../../../../../components/StepperTabs/StepperTabs';
import { validateDealEvaluationData } from '../../../../../../../../../../../../util/validations';
import LimitRoadmap from '../components/LimitRoadmap/LimitRoadmap';
import {uw_signal_types} from "../components/DealEvaluation"
import ConfirmTotUpdate from '../../../../../../../../../../../../components/ConfirmTotUpdateModal/ConfirmTotUpdateModal';
import {getMoratMonths} from "../../../../../../../../../../../../common/ApiHelpers";
import { isNil } from 'lodash';
import { EXPIRED, WAITLISTED } from '../../../../../DocumentVault/consts/consts';
dayjs.extend(customParseFormat);

export default function AddOnModerate({
    monthlyViewData,
    moderateRow,
    monthlyData,
    fetchMonthlyData,
}) {
    const { APPROVED, SPARK_APPROVED, APPROVED_RUNAWAY } = ONBOARDING_STATUS;
    const { RECUR_LIMIT, STANDARD, MAX, DEBT_SYNDICATION } = LIMIT_TYPES;
    const { SENT, ACCEPTED, UPDATED_NOT_SENT } = TOT_STATUS;
    
    // const STEPS = ["Review Financial Data", "Prepare Terms"];

    const MIS = "MIS";
    const PROVISIONAL_FINANCIALS='Provisional Financials'
    const INVOICING = "Invoicing Data";
    const DEBT_SCHEDULE = "Debt Schedule";
    const GSTIN = 'gstin';

    const ADD_BANK = "is_add_bank_account";

    const MAB = "MAB";
    const DIRECT_COLLECTION = "Direct Collection";

    const DEFAULT_STATUS_INFO = {
        1: {
            category: PROVISIONAL_FINANCIALS,
            status: "",
            comment: ""
        },
        2: {
            category: "Invoicing",
            status: "",
            comment: ""
        },
        3: {
            category: DEBT_SCHEDULE,
            status: "",
            comment: ""
        },
        4: {
            category: "Bank Statement",
            status: "",
            comment: ""
        }
    };

    const STEPS =[
        {
            label: "Review Financial Data",
            value: 1
        },
        {
            label: MODERATION.RRF_TERM,
            value: 2
        },
        {
            label: MODERATION.DEBT_SYNDICATION_TERM,
            value: 3

        }
    ];

    const DEFAULT_STATUS_OPTIONS = ["Need Information", "Approved", "Approved-Runway<3M", "Waitlisted",  "Approved For Debt Syndication"];
    const addOnId = moderateRow?._id;
    const dispatch = useDispatch();
    const investeeOrgData = useSelector(getInvesteesOrganization);
    const investeeOrgId = investeeOrgData?._id;
    const investeeOnboardedId = investeeOrgData?.investee_onboarded_id;
    const statusInfoRef = useRef(DEFAULT_STATUS_INFO);
    const currentStepRef = useRef(1);
    const [currentStep, setCurrentStep] = useState(1);
    const [moderatorDetails, setModeratorDetails] = useState([]);
    const [statusOptions, setStatusOptions] = useState(DEFAULT_STATUS_OPTIONS);
    const [statusInfo, setStatusInfo] = useState(DEFAULT_STATUS_INFO);
    const [showReqStatusOptions, setShowReqStatusOptions] = useState(false);
    const [isModerating, setIsModerating] = useState(false);
    const [comment, setComment] = useState('');
    const [addnInfo, setAddnInfo] = useState([]);
    const [isAddnInfo, setIsAddnInfo] = useState(false);
    const [status, setStatus] = useState("");
    const statusRef = useRef("");
    const [note1, setNote1] = useState("");
    const [standardCP, setStandardCP] = useState([""]);
    const [standardCS, setStandardCS] = useState([""]);
    const [note2, setNote2] = useState("");
    const [maxCP, setMaxCP] = useState([""]);
    const [maxCS, setMaxCS] = useState([""]);
    const [recurLimitCS, setRecurLimitCS] = useState([""]);
    const [recurLimitCP, setRecurLimitCP] = useState([""]);
    const [note3, setNote3] = useState("");
    const [note4, setNote4] = useState("");
    const [CP4, setCP4] = useState([""]);
    const [CS4, setCS4] = useState([""]);
    const [totData, setTotData] = useState({});
    const [userTotData, setUserTotData] = useState();
    const [showMaxLimit, setShowMaxLimit] = useState(false);
    const [initialUserTotData, setInitialUserTotData] = useState();
    const [showStandardLimit, setShowStandardLimit] = useState(false);
    const [coborrowerList, setCoborrowerList] = useState([]);
    const [userNameForCoborrower, setUserNameForCoborrower] = useState(null);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [lastEmail, setLastEmail] = useState(null);
    const [totId, setTotId] = useState();
    const [isTotAccepted,setIsTotAccepted] = useState(false);
    const onboardingData = useSelector(state => getInvesteeOnboardingData(state));
    const rrfToggleInfo=useSelector(getRrfToggleDetails);
    const debtSyndicationToggleInfo=useSelector(getDebtSyndicationToggleDetails);
    const gstAnnualRevData=useSelector(getGstAnnualRev);
    const [rrfTermToggle,setRrfTermToggle]=useState(false);
    const [debtSyndicationToggle,setDebtSyndicationToggle]=useState(false);
    const [dealReportState, setDealReportState] = useState({});
    const [limitRoadmapData, setLimitRoadmapData] = useState({
        limitRoadmap: '',
        tranches: [
            {
                tenure: '',
                amount: '',
                arr: '',
                comments: ''
            }
        ]
    });
    const [showTotUpdatePopUp, setShowTotUpdatePopUp] = useState(false);
    const [modalMessaging, setModalMessaging] = useState({})
    const [currentModerateTab, setCurrentModerateTab] = useState(PREPARE_TERMS_TAB.RRF_TERMS);

    const [detectChange, setDetectChange] = useState({
        [RECUR_LIMIT]: false,
        [STANDARD]: false,
        [MAX]: false,
        [DEBT_SYNDICATION]: false
    });
    const [totalAvailableLimit, setTotalAvailableLimit] = useState();
    const [toggleState, setToggleState] = useState({
        rrfToggle: {
          updatedBy: "System",
          updatedAt:formatDate(moderateRow.created_at,'/')
        },
        debtSynToggle: {
          updatedBy: "System",
          updatedAt: formatDate(moderateRow.created_at,'/')
        }
      });
    const [moratDropdownList,setMoratDropdownList] = useState({});
    const [isExpired, setIsExpired] = useState(false);
    const [isWaitListed, setIsWaitListed] = useState(false);
    
    const getMoraDropdownList = async() => {
        const res = await getMoratMonths([0,1,2,3])
        if(res){
            const modifiedRes = {} 
            Object.keys(res).forEach((key, index) => {
                modifiedRes[key] = `${res[key]} (${index}M)`;
            });
            setMoratDropdownList(modifiedRes)
        }
    }
    useEffect(()=>{
        getMoraDropdownList()
    },[])
    const getCurrentTotCount=(data)=>{
        let count = 0;
        if(data.hasOwnProperty(RECUR_LIMIT)){
            count++;
        }
        if(data.hasOwnProperty(STANDARD)){
            count++;
        }
        if(data.hasOwnProperty(MAX)){
            count++;
        }
        if(data.hasOwnProperty(DEBT_SYNDICATION)){
            count++;
        }
        return count;
    }
    const handleToggle=()=>{
        // for RRF
         const rrf_toggle_state = "rrfToggle";
         const debt_syn_toggle_state = "debtSynToggle";
           const capital_req=moderateRow?.add_on_amount||0
           if(rrfToggleInfo&&rrfToggleInfo!==''&&rrfToggleInfo?.updatedBy){
            setToggleState(prevState => ({
                ...prevState,
                [rrf_toggle_state]: {
                  ...prevState[rrf_toggle_state],
                  updatedBy: rrfToggleInfo?.updatedBy,
                  updatedAt: formatDate(rrfToggleInfo?.updatedAt,'/')
                }
              }));
            if(rrfToggleInfo?.isOn) setRrfTermToggle(true);
            else setRrfTermToggle(false)
           }
           else{
            //case 2
            setToggleState(prevState => ({
                ...prevState,
                [rrf_toggle_state]: {
                  ...prevState[rrf_toggle_state],
                  updatedBy: "System",
                  updatedAt: formatDate(moderateRow.created_at,'/')
                }
              }));
           }
        // for debt syndication 
        if(debtSyndicationToggleInfo&&debtSyndicationToggleInfo!==''&&debtSyndicationToggleInfo?.updatedBy){
            setToggleState(prevState => ({
                ...prevState,
                [debt_syn_toggle_state]: {
                  ...prevState[debt_syn_toggle_state],
                  updatedBy: debtSyndicationToggleInfo?.updatedBy,
                  updatedAt: formatDate(debtSyndicationToggleInfo?.updatedAt,'/')
                },
              }));
            if(debtSyndicationToggleInfo?.isOn) setDebtSyndicationToggle(true);
           else setDebtSyndicationToggle(false)
        }
        else{
            //case2
            setToggleState(prevState => ({
                ...prevState,
                [debt_syn_toggle_state]: {
                  ...prevState[debt_syn_toggle_state],
                  updatedBy: "System",
                  updatedAt: formatDate(moderateRow.created_at,'/')
                },
              }));
            if(Number(capital_req)>=50000000&&(Number(gstAnnualRevData)>=20||Number(onboardingData?.arr_funding_cal)>=20)){
                setDebtSyndicationToggle(true);
            }
            else{
                setDebtSyndicationToggle(false);
            }
        }
    }

    useEffect(()=>{
        handleToggle();
    },
    [gstAnnualRevData,rrfToggleInfo,debtSyndicationToggleInfo,onboardingData,moderateRow])

    useEffect(() => {
        setIsModerating (false);
    }, [currentModerateTab]);
    useEffect(()=>{
        if(currentStep===2){
            setIsSaveClicked(!rrfTermToggle)
            setCurrentModerateTab(PREPARE_TERMS_TAB.RRF_TERMS);
        }
        else if(currentStep===3){
            setIsSaveClicked(!debtSyndicationToggle)
            setCurrentModerateTab(PREPARE_TERMS_TAB.DS_TERMS);
        }
        else 
            setIsSaveClicked(false)

    },[currentStep])

    useEffect(()=>{
        if(currentStep===2){
            setIsSaveClicked(!rrfTermToggle)
        }
        else if(currentStep===3){
            setIsSaveClicked(!debtSyndicationToggle)
        }
    },[rrfTermToggle,debtSyndicationToggle])

    useEffect(() => {
        setStatusOptions(DEFAULT_STATUS_OPTIONS);
        fetchTotData();
        fetchModeratorComment();
        fetchMonthlyStatusInfo();
        dispatch(fetchToggleInfo(addOnId,investeeOrgId,true));
        dispatch(fetchDealReport(addOnId, investeeOrgId));
        handleToggle();
        setIsExpired(moderateRow.status === EXPIRED);
        setIsWaitListed(moderateRow.status === WAITLISTED);
        setAddnInfo(moderateRow?.additionalRequiredDocuments ?? [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moderateRow]);

    useEffect(() => {
        if (!isEmpty(totData) && totData.baseTotId) fetchAvailableLimit(totData.baseTotId);
    }, [totData]);

    useEffect(() => {
        getUserNameForCoborrower();
    }, [])

    const fetchAvailableLimit = async (totId) => {
        const res = await callApi("tot-history/get-available-limit", "post", {
            investeeOrganizationId: investeeOrgId,
            totType: "add_on_limit",
            totId
        });
        if (res.status === API_STATUS.SUCCESS && res.data) setTotalAvailableLimit(res.data.availableTradingLimit);
    }

    const getUserNameForCoborrower = () => {
        callApi(
            `admin/fetch-username-for-coborrower`,
            'post', {
            investee_organization_id: investeeOrgId
        }
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                setUserNameForCoborrower(
                    res.data
                );
            }
            else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
        });
    };

    const fetchModeratorComment = () => {
        callApi("get-comment", "post", {
            identifier_id: addOnId,
            type: "tot_add_on"
        }).then(res => {
            if (res.status === API_STATUS.SUCCESS) setModeratorDetails(res.data);
        })
    }

    const fetchTotData = (isUpdateToBeCalled = false,clonedTotData) => {
        callApi("admin/tot-history/get-prepare-terms-tot-details", "post", {
            investee_organization_id: investeeOrgId,
            add_on_limit_id: addOnId
        }).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                const data = { ...res.data };
                setIsTotAccepted(res.data?.base_tot_status === ACCEPTED);
                
                setTotId(data.baseTotId);
                setTotData(data);
                if (data?.[STANDARD]?.secondary_rcm?.Coborrower) setCoborrowerList({ Standard: [...data?.[STANDARD]?.secondary_rcm?.Coborrower] })
                if (data?.[MAX]?.secondary_rcm?.Coborrower) setCoborrowerList({ Max: [...data?.[MAX]?.secondary_rcm?.Coborrower] })
                if (!isEmpty(data)) updateTotData(data);
                else updateToDefaultUserTotData({});
                intitalizeLimitRoadmapData(res.data?.AdminCapitalRoadmap);
            }
            else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
        }).then(() => {
            if(isUpdateToBeCalled) updateTot(clonedTotData);
        })
    }

    const intitalizeLimitRoadmapData = (data) => {
        if(isEmpty(data)) return;
        const firstTranchTenure = data?.tranches?.[0]?.tenure;
        setLimitRoadmapData({
            limitRoadmap: `${data.limitRoadmap || ''}`,
            tranches: data?.tranches?.map(tranch => ({
                amount: `${tranch?.amount || ''}`,
                arr: `${tranch?.arr || ''}`,
                comments: `${tranch?.comments || ''}`,
                tenure: `${getTranchTenure(tranch.tenure) || ''}`
            }))
        })
    }

    const fetchMonthlyStatusInfo = () => {
        updateMonthlyStatusInfo(moderateRow);
    }

    const updateMonthlyStatusInfo = (data) => {
        let obj = DEFAULT_STATUS_INFO;
        const {
            status,
            mis_status,
            invoicing_status,
            bank_transactions_status,
            last_email_sent_at,
            doc_comments,
            is_add_bank_account,
            bank_account,
            debtSchedule,
            gstin,
        } = data;
        let index=1;
        obj[index].status = mis_status ? mis_status : "";
        obj[index++].comment = doc_comments?.mis ? doc_comments.mis : "";
        obj[index].status = invoicing_status ? invoicing_status : "";
        obj[index++].comment = doc_comments?.invoicing ? doc_comments.invoicing : "";
        obj[index] = {...obj[index], ...debtSchedule} 
        obj[index].status = debtSchedule?.status ? debtSchedule?.status : "";
        obj[index++].comment = debtSchedule?.comment ? debtSchedule?.comment : "";
        if(bank_account){
            for(let data of bank_account){
                obj[index]={}
                obj[index].status = bank_transactions_status?.[data.account_number] ? bank_transactions_status?.[data.account_number] : "";
                obj[index].category = 'Bank (' + data.bank_name + ' - ' + data.account_number+ ')';
                obj[index].comment = doc_comments?.bank_accounts?.[data.account_number] ? doc_comments.bank_accounts[data.account_number] : "";
                obj[index].account_number =data.account_number
                obj[index].idx=index;
                index++;
            }
        }
        // Add On need info gstin support
        if(!isEmpty(gstin)) {
            Object.keys(gstin).forEach((gstn) => {
                let stateName = gstin[gstn]?.state;
                obj[index] = {};
                obj[index].status = gstin[gstn].needInfoStatus ? gstin[gstn].needInfoStatus : "";
                obj[index].category = `GST (${
                  stateName ? `${stateName} - ` : ""
                }${gstn})${
                  gstin[gstn]?.active_status === GST_INACTIVE
                    ? ` - ${gstin[gstn].active_status}`
                    : ""
                }`;
                obj[index].emailCategory = `GST (${stateName ? `${stateName} - ` : ""}${gstn})`;
                obj[index].comment = gstin[gstn].comments ? gstin[gstn].comments : "";
                // identifier for the gstn
                obj[index].identifier = gstn;
                index++;
            })
        }

        obj[index] = {};
        obj[index].category = ADD_BANK;
        obj[index].status = is_add_bank_account ? is_add_bank_account : false;
        setStatusInfo(JSON.parse(JSON.stringify(obj)));
        statusInfoRef.current = JSON.parse(JSON.stringify(obj));
        if (status) {
            setStatus(status);
            statusRef.current = status;
        }
        setComment(data.comment);
        setLastEmail(last_email_sent_at);
    }

    const updateToDefaultUserTotData = ({ category, oldRcm }) => {
        const obj = {
            tradingLimit: investeeOrgData?.trading_limit,
            irr: investeeOrgData?.irr,
            roi: getRoiFromIrr(investeeOrgData?.irr),
            price: investeeOrgData?.max_tenure_price,
            minTenure: investeeOrgData?.custom_tenure_limit_min,
            maxTenure: investeeOrgData?.custom_tenure_limit_max,
            fee: investeeOrgData?.fees,
            isWaived: investeeOrgData?.recur_fees_waived,
            feeRes: userTotData?.[RECUR_LIMIT]?.feeRes ?? {},
            moratorium_months: investeeOrgData?.moratorium_months,
            collectionMethods: {
                ...oldRcm
            },
        }
        if (investeeOrgData?.mab > 0) {
            obj.collectionMethods[MAB] = {amount : investeeOrgData.mab}
        }
        if (!category) setUserTotData({
            [RECUR_LIMIT]: JSON.parse(JSON.stringify(obj)),
            [STANDARD]: JSON.parse(JSON.stringify(obj)),
            [MAX]: JSON.parse(JSON.stringify(obj)),
        })
        else setUserTotData(temp => {
            temp[category] = obj;
            return temp;
        })
    }

    const updateTotData = (data, initialDataUpdateRequired = true) => {
        let obj = { ...userTotData };
        const defaultObj = {
            tradingLimit: investeeOrgData?.trading_limit,
            irr: investeeOrgData?.irr,
            roi: getRoiFromIrr(investeeOrgData?.irr),
            price: investeeOrgData?.max_tenure_price,
            minTenure: investeeOrgData?.custom_tenure_limit_min,
            maxTenure: investeeOrgData?.custom_tenure_limit_max,
            fee: investeeOrgData?.fees,
            isWaived: investeeOrgData?.recur_fees_waived,
            moratorium_months: investeeOrgData?.moratorium_months,
            collectionMethods: {}
        }
        if (investeeOrgData?.mab > 0) {
            defaultObj.collectionMethods[MAB] = {amount : investeeOrgData.mab}
        }
        if (DEBT_SYNDICATION in data) 
            obj = updateCategoryInUserState(DEBT_SYNDICATION, data, obj);
        if (!(RECUR_LIMIT in data) && !(STANDARD in data) && !(MAX in data)) {
            obj[RECUR_LIMIT] = JSON.parse(JSON.stringify(defaultObj));
            obj[STANDARD] = JSON.parse(JSON.stringify(defaultObj));
            obj[MAX] = JSON.parse(JSON.stringify(defaultObj));
        } else {
            obj = updateCategoryInUserState(RECUR_LIMIT, data, obj);
            obj = updateCategoryInUserState(STANDARD, data, obj);
            obj = updateCategoryInUserState(MAX, data, obj);
        }   
        setUserTotData(obj);
        if (initialDataUpdateRequired) setInitialUserTotData(obj);
        updateTexts(data);
        if (MAX in data) setShowMaxLimit(true);
        if (STANDARD in data) setShowStandardLimit(true);
    }

    const updateTexts = (data) => {
        if (DEBT_SYNDICATION in data) {
            if (!isEmpty(data[DEBT_SYNDICATION]?.cp)) setCP4(data[DEBT_SYNDICATION].cp);
            if (!isEmpty(data[DEBT_SYNDICATION]?.cs)) setCS4(data[DEBT_SYNDICATION].cs);
            if ("note" in data[DEBT_SYNDICATION]) setNote4(data[DEBT_SYNDICATION].note);
        }
        if (RECUR_LIMIT in data) {
            if (!isEmpty(data[RECUR_LIMIT]?.cp)) setRecurLimitCP(data[RECUR_LIMIT].cp);
            if (!isEmpty(data[RECUR_LIMIT]?.cs)) setRecurLimitCS(data[RECUR_LIMIT].cs);
            if ("note" in data[RECUR_LIMIT]) setNote3(data[RECUR_LIMIT].note);
        }
        if (STANDARD in data) {
            if (!isEmpty(data[STANDARD]?.cp)) setStandardCP(data[STANDARD].cp);
            if (!isEmpty(data[STANDARD]?.cs)) setStandardCS(data[STANDARD].cs);
            if ("note" in data[STANDARD]) setNote1(data[STANDARD].note);
        }
        if (MAX in data) {
            if (!isEmpty(data[MAX]?.cp)) setMaxCP(data[MAX].cp);
            if (!isEmpty(data[MAX]?.cs)) setMaxCS(data[MAX].cs);
            if ("note" in data[MAX]) setNote2(data[MAX].note);
        }
    }

    const updateCategoryInUserState = (category, data, obj) => {
        if (isEmpty(obj)) obj = {};
        if (isEmpty(obj[category])) obj[category] = {};
        obj[category].tradingLimit = data[category]?.new_unsanctioned_limit;
        obj[category].irr = data[category]?.irr;
        obj[category].roi = data[category]?.roi;
        obj[category].moratorium_months = data[category]?.moratorium_months;
        obj[category].price = data[category]?.max_tenure_price;
        obj[category].minTenure = data[category]?.custom_tenure_limit_min;
        obj[category].maxTenure = data[category]?.custom_tenure_limit_max;
        obj[category].fee = data[category]?.fees;
        obj[category].isWaived = data[category]?.is_recur_fee_waived;
        obj[category].feeRes = data[category]?.feeRes ?? {};
        obj[category].showRoi = data?.showRoi;
        obj[category].collectionMethods = data[category]?.secondary_rcm ?? {};
        obj[category].totalLimit = data[category]?.total_limit;
        obj[category].totStatus = data[category]?.tot_status;
        if (category === DEBT_SYNDICATION) {
            obj[category].financingStructure = data[category]?.financingStructure;
            obj[category].engagementSignDate = data[category]?.engagementSignDate;
            obj[category].signedEngagementLetter = data[category]?.signedEngagementLetter;
            obj[category].elUploadedType = data[category]?.elUploadedType;
            obj[category].maxIrr = data[category]?.maxIrr;
            obj[category].security = data[category]?.security;
        }
        if (data[category]?.mab > 0) {
            obj[category].collectionMethods[MAB] = {
                amount: data[category].mab
            };
        }
        return obj;
    }

    const handleTotValidation = (obj, terms) => {
        let message = '';
        let isValid = true;
        
        if(currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS && terms === PREPARE_TERMS_TAB.DS_TERMS){
            if (isNil(obj?.maxIrr)) {
                message = "All details are not filled";
                isValid = false;
            }
            if (obj?.maxIrr && obj.maxIrr < obj.irr) {
                message = "Maximum ROI cannot be less than the minimum ROI";
                isValid = false;
            } 

            if(obj?.maxTenure > 0 && obj.maxTenure < obj.minTenure){
                message = "Maximum Tenure cannot be less than the Minimum Tenure";
                isValid = false; 
            } 
        } else if(terms === PREPARE_TERMS_TAB.RRF_TERMS){
            if (!obj || !Number(obj.tradingLimit) || !Number(obj.irr) || !Number(obj.minTenure) || !Number(obj.maxTenure) || !Number(obj.fee) || (obj?.signedEngagementLetter === "" && obj?.elUploadedType !== "Standard EL") ) {
                message = "All details are not filled";
                isValid = false;
            } 
        }

        if(!isValid) setIsSaveClicked(false);
        return {
            isValid,
            message,
        }
    }

    const onCancel = () => {
        if (currentStep === 1) {
            if (showReqStatusOptions) setShowReqStatusOptions(false);
            if (!isEqual(statusInfo, statusInfoRef.current)) setStatusInfo(JSON.parse(JSON.stringify(statusInfoRef.current)));
            if (status !== statusRef.current) setStatus(statusRef.current);
        }
        else if (currentStep === 2||currentStep === 3) {
            let data = totData;
            if (data?.[STANDARD]?.secondary_rcm?.Coborrower && coborrowerList[STANDARD])
                data[STANDARD].secondary_rcm.Coborrower = coborrowerList[STANDARD];
            if (data?.[MAX]?.secondary_rcm?.Coborrower && coborrowerList[MAX])
                data[MAX].secondary_rcm.Coborrower = coborrowerList[MAX];
            updateTotData(data, false);
            setDetectChange({
                [DEBT_SYNDICATION]: false,
                [RECUR_LIMIT]: false,
                [STANDARD]: false,
                [MAX]: false
            });
        }
        setIsModerating(false);
    }

    const onSave = (adminUpdateOveride = false) => {
        setIsSaveClicked(true);
        const onSuccess = () => {
            fetchMonthlyData();
            fetchTotData();
            setIsModerating(false);
            setIsSaveClicked(false);
        }
        if (currentStep === 1) {
            const changedDealReport = {...dealReportState};

            if (status ===  APPROVED || status === WAITLISTED || status === APPROVED_RUNAWAY){
                if ( changedDealReport.runway == null){
                    showNotification(SHOW_NOTIFICATION.ERROR, "Runway is mandatory");
                    return;
                }
                if ( changedDealReport.debtPercent == null){
                    showNotification(SHOW_NOTIFICATION.ERROR, "Debt(%) is mandatory");
                    return;
                } if (changedDealReport.ebitdaPercent == null) {
                    showNotification(SHOW_NOTIFICATION.ERROR, "EBITDA(%) is mandatory");
                    return;
                }
            }
            const obj = {
                status,
                comment
            }
            let endpoint;
            obj.doc_comments = {};
            endpoint = `moderate/${investeeOnboardedId}?path=${moderateRow?.due_date}`;
            let index=1;
            Object.keys(statusInfo).forEach(data=>{
                if(statusInfo[data].category===PROVISIONAL_FINANCIALS){
                    obj.mis_status = statusInfo[index].status ? statusInfo[index].status : "Open";
                    obj.doc_comments.mis = statusInfo[index].comment ? statusInfo[index].comment : "";
                }
                else if(statusInfo[data].category==="Invoicing"){
                    obj.invoicing_status = statusInfo[index].status ? statusInfo[index].status : "Open";
                    obj.doc_comments.invoicing = statusInfo[index].comment ? statusInfo[index].comment : "";
                }
                else if(statusInfo[data].category === DEBT_SCHEDULE){
                    obj.debt_schedule = {...statusInfo[data]};
                    obj.debt_schedule.status = statusInfo[index].status ? statusInfo[index].status : "Open";
                    obj.debt_schedule.comment = statusInfo[index].comment ? statusInfo[index].comment : "";
                }
                else if(statusInfo[data].category==="is_add_bank_account"){
                    obj[ADD_BANK]=statusInfo[index]?.status ? statusInfo[index].status  : false;
                }
                else if(statusInfo[data].idx && statusInfo[data].account_number){
                    if(!obj.bank_transactions_status) obj.bank_transactions_status = {};
                    obj.bank_transactions_status[statusInfo[data].account_number] = statusInfo[statusInfo[data].idx]?.status ? statusInfo[statusInfo[data].idx].status : "Open";
                    if(!obj.doc_comments.bank_accounts) obj.doc_comments.bank_accounts = {};
                    obj.doc_comments.bank_accounts[statusInfo[data].account_number] = statusInfo[statusInfo[data].idx]?.comment ? statusInfo[statusInfo[data].idx].comment : "";
                }
                // Add on need info gstin support
                else if(statusInfo[data].identifier) {
                    if(!obj.gstin) obj.gstin = {};
                    obj.gstin[statusInfo[data]?.identifier] = {
                        category: statusInfo[data]?.emailCategory,
                        gstin_number: statusInfo[data]?.identifier,
                        status: statusInfo[index].status ? statusInfo[index].status : "Open",
                        comments: statusInfo[index].comment ? statusInfo[index].comment : "",
                    }
                }
                index++;
            })
            
            if (isAddnInfo) {
                obj.additionalRequiredDocuments =  addnInfo.map((item)=>{
                    const {status, query, requireDocument, requireText, comment, otherValue}=item
                    return{
                        status,
                        query : otherValue?.length ? otherValue: query,
                        requireDocument,
                        requireText,
                        comment
                    }
                });;
            }

            callApi(endpoint, "post", obj).then(res => {
                if (res.status === API_STATUS.SUCCESS) {
                    onSuccess();
                    showNotification(SHOW_NOTIFICATION.SUCCESS, "Saved successfully");
                    updateMonthlyStatusInfo(res.data);
                    fetchMonthlyData();
                }
                else {
                    setIsSaveClicked(false);
                    showNotification(SHOW_NOTIFICATION.ERROR, res?.data?.message ?? GENERIC_ERROR);
                }
            }).catch(() => {
                setIsSaveClicked(false);
                showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
            });
        }
        else if (currentStep === 2||currentStep === 3) {
            const clonedTotData = {...totData};
            const debtSyndicationData = {};
            if ("old_rcm" in clonedTotData) delete clonedTotData.old_rcm;
            if("base_tot_status" in clonedTotData) delete clonedTotData.base_tot_status;
            if("acceptedTot" in clonedTotData) delete clonedTotData.acceptedTot;
            if ("baseTotId" in clonedTotData) delete clonedTotData.baseTotId;
            if ("emailSentDate" in clonedTotData) delete clonedTotData.emailSentDate;
            if("AdminCapitalRoadmap" in clonedTotData)  delete clonedTotData.AdminCapitalRoadmap;
            if (DEBT_SYNDICATION in clonedTotData) {
                debtSyndicationData[DEBT_SYNDICATION] = clonedTotData[DEBT_SYNDICATION];
                delete clonedTotData[DEBT_SYNDICATION];
            }

            if((!isTotAccepted && limitRoadmapData?.limitRoadmap) && !isLimitRoadmapDataValid()) {
                setIsSaveClicked(false);
                return;
            }
            if(showMaxLimit) {
                const maxTradingLimit = Number(userTotData[MAX]?.tradingLimit);
                const stdTradingLimit = Number(userTotData[STANDARD]?.tradingLimit);
                if(maxTradingLimit < stdTradingLimit) {
                    showNotification(SHOW_NOTIFICATION.ERROR,"Max trading limit must be greater than standard trading limit")
                    setIsSaveClicked(false);
                    return;
                }
            }
            const currentTotCount = getCurrentTotCount(clonedTotData);
            const createDebtSyndicationLimit = isEmpty(debtSyndicationData);
            const createRecurLimit = isEmpty(clonedTotData);
            const createStdLimit = (currentTotCount === 1 && showStandardLimit);
            const createMaxLimit = (currentTotCount === 2 && showMaxLimit);
            const isCreateToBeCalled = createRecurLimit || createStdLimit || createMaxLimit;
            const isUpdateToBeCalled = (currentTotCount === 1 && detectChange[RECUR_LIMIT]) 
                || (currentTotCount === 2 && (detectChange[RECUR_LIMIT] || detectChange[STANDARD])) 
                || (currentTotCount === 3 && (detectChange[RECUR_LIMIT] || detectChange[STANDARD] || detectChange[MAX]))
                || (currentTotCount === 3 && !showMaxLimit)
                || (currentTotCount === 2 && !showStandardLimit)
                || (currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS && detectChange[DEBT_SYNDICATION]);
            if(isCreateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
                createTot(isUpdateToBeCalled,clonedTotData)
            } else if (createDebtSyndicationLimit && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS) {
                createTot(false,debtSyndicationData)
            } else if (isUpdateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
                updateTot(clonedTotData , adminUpdateOveride);
            } else if (isUpdateToBeCalled && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS) {
                updateTot(debtSyndicationData);
            }
        }
        setIsSaveClicked(false);
    }

    const createTot = (isUpdateToBeCalled = false,clonedTotData) => {
        const currentTotCount = getCurrentTotCount(clonedTotData);
        callCreateTotApi({
            category: {
                [DEBT_SYNDICATION]: (currentTotCount === 0 && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS),
                [RECUR_LIMIT]: (currentTotCount === 0 && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS),
                [STANDARD]: (currentTotCount === 0 || currentTotCount === 1) && showStandardLimit && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [MAX]: (currentTotCount === 0 || currentTotCount === 1 || currentTotCount === 2) && showMaxLimit && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS
            },
            isUpdateToBeCalled,
            clonedTotData
        });
    }

    const updateTot = (clonedTotData , adminUpdateOveride = false) => {
        const currentTotCount = getCurrentTotCount(clonedTotData);
        callUpdateTotApi({
            category: {
                [DEBT_SYNDICATION]: detectChange[DEBT_SYNDICATION] && currentModerateTab === PREPARE_TERMS_TAB.DS_TERMS,
                [RECUR_LIMIT]: detectChange[RECUR_LIMIT] && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [STANDARD]: (detectChange[STANDARD] || (currentTotCount === 2 && !showStandardLimit)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS,
                [MAX]: (detectChange[MAX] || (currentTotCount === 3 && !showMaxLimit)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS
            }, 
            isDeletedInMax: currentTotCount === 3 && !showMaxLimit,
            isDeletedInStandard: currentTotCount === 2 && !showStandardLimit,
            adminUpdateOveride
        });
    }

    const getObjToSend = (category) => {
        const emptyStrRemover = (state, setState) => {
            const arr = state.filter(item => item.trim() !== "");
            return arr;
        }
        const obj = { ...totData[category] };
        obj.identifier = category;
        obj.new_unsanctioned_limit = Number(userTotData[category]?.tradingLimit) ?? userTotData[category]?.tradingLimit;

        if(((showMaxLimit && limitRoadmapData?.limitRoadmap && category === MAX) || ((showStandardLimit && !showMaxLimit) && limitRoadmapData?.limitRoadmap && category === STANDARD)) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS)
        obj.total_limit = Number(limitRoadmapData.limitRoadmap);
        else if((showStandardLimit && showMaxLimit) && limitRoadmapData?.limitRoadmap && category === STANDARD && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) 
        obj.total_limit = obj.new_unsanctioned_limit;
        else obj.total_limit = null;

        obj.irr = Number(userTotData[category]?.irr) ?? userTotData[category]?.irr;
        obj.roi = Number(userTotData[category]?.roi) ?? userTotData[category]?.roi;
        obj.moratorium_months = Number(userTotData[category]?.moratorium_months) ?? userTotData[category]?.moratorium_months;
        obj.custom_tenure_limit_min = Number(userTotData[category]?.minTenure) ?? userTotData[category]?.minTenure;
        obj.custom_tenure_limit_max = Number(userTotData[category]?.maxTenure) ?? userTotData[category]?.maxTenure;
        obj.fees = Number(userTotData[category]?.fee) ?? userTotData[category]?.fee;
        obj.is_recur_fee_waived = userTotData[category]?.isWaived;
        obj.secondary_rcm = userTotData[category]?.collectionMethods;
        obj.mab = Number(userTotData[category]?.collectionMethods?.[MAB]?.amount) ?? userTotData[category]?.collectionMethods?.[MAB]?.amount;
        if (category === DEBT_SYNDICATION) {
            if(userTotData[category]?.financingStructure) obj.financingStructure = userTotData[category]?.financingStructure;
            if(userTotData[category]?.engagementSignDate) obj.engagementSignDate = userTotData[category]?.engagementSignDate;
            if(userTotData[category]?.signedEngagementLetter) obj.signedEngagementLetter = userTotData[category]?.signedEngagementLetter;
            if( userTotData[category]?.elUploadedType)  obj.elUploadedType = userTotData[category]?.elUploadedType;
            if(userTotData[category]?.security) obj.security = userTotData[category]?.security;
            obj.maxIrr = Number(userTotData[category]?.maxIrr);
        }
        delete obj.secondary_rcm?.mab;
        obj.cp = category === RECUR_LIMIT ? emptyStrRemover([...recurLimitCP], setRecurLimitCP) : category === STANDARD ? emptyStrRemover([...standardCP], setStandardCP) : category === DEBT_SYNDICATION ? emptyStrRemover([...CP4], setCP4): emptyStrRemover([...maxCP], setMaxCP);
        obj.cs = category === RECUR_LIMIT ? emptyStrRemover([...recurLimitCS], setRecurLimitCS) : category === STANDARD? emptyStrRemover([...standardCS], setStandardCS) : category === DEBT_SYNDICATION? emptyStrRemover([...CS4], setCS4): emptyStrRemover([...maxCS], setMaxCS);
        obj.note = category === STANDARD ? note1 : category === MAX ? note2 : category === DEBT_SYNDICATION ? note4 : note3;
        obj.add_on_limit_id = addOnId;
        return obj;
    }

    const handleValidation = (category) => {
        const COBORROWER = "Coborrower";
        const START_DATE = "start_date";
        const END_DATE = "end_date";
        const AMOUNT = "amount";
        let isValid = true;
        let message = '';
        const methods = { ...userTotData[category].collectionMethods };
        const validateDates = (type) => {
            if (!methods[type]?.[START_DATE]) {
                isValid = false;
                message = `Start date of ${type} is not entered`;
            }
            else if (methods[type]?.[START_DATE] < 1) {
                isValid = false;
                message = `Start date of ${type} is invalid`;
            }
            else if (!methods[type]?.[END_DATE]) {
                isValid = false;
                message = `End date of ${type} is not entered`;
            }
            else if (Number(methods[type]?.[END_DATE]) > 31) {
                isValid = false;
                message = "End date can not be greater than 31";
            }
            else if (Number(methods[type]?.[START_DATE]) >= Number(methods[type]?.[END_DATE])) {
                isValid = false;
                message = "Start date can not be greater than or equal to end date";
            }
        }
        const validateAmount = (category) => {
            if (typeof methods[category] !== "object" || !methods[category] || !(AMOUNT in methods[category]) || !methods[category][AMOUNT]) {
                isValid = false;
                message = `Please enter amount for ${category}`;
            }
        }
        const validateCoborrower = (category) => {
            const TYPE = "type";
            const NAME = "name";
            if (!isEmpty(methods[category])) {
                for (const data of methods[category]) {
                    if (!(data[NAME] && data[TYPE])) {
                        isValid = false;
                        message = `Please enter coborrower details`
                        return;
                    }
                }
            }
        }
        if (MAB in methods) validateAmount(MAB);
        if (isValid && DIRECT_COLLECTION_KEY in methods) validateAmount(DIRECT_COLLECTION_KEY);
        if (isValid && RAZORPAY_SPLIT_KEY in methods) validateDates(RAZORPAY_SPLIT_KEY);
        if (isValid && CASHFREE_SPLIT_KEY in methods) validateDates(CASHFREE_SPLIT_KEY);
        if (isValid && COBORROWER in methods) validateCoborrower(COBORROWER);
        return {
            isValid,
            message
        }
    }


    const onCreateSuccess = (isUpdateToBeCalled = false,tots = [],updatedData,clonedTotData) => {
        let createdTotIdentifier = null;
        if(tots?.length === 1) {
            createdTotIdentifier = tots?.[0]?.identifier;
            setTotData((tots) => ({
                ...tots,
                [createdTotIdentifier] : {
                    ...tots[createdTotIdentifier],
                    ...updatedData
                }
            }))
            if(isUpdateToBeCalled) updateTot(clonedTotData);
        }
        fetchTotData(isUpdateToBeCalled,clonedTotData);
        setIsModerating(false);
        dispatch(fetchInvesteeDetails(investeeOrgId));
    }

    const callCreateTotApi = (props) => {
        const { category,isUpdateToBeCalled,clonedTotData , adminUpdateOveride} = props ?? {};
        if (category?.[STANDARD]) {
            const validateStandard = handleTotValidation(userTotData[STANDARD], PREPARE_TERMS_TAB.RRF_TERMS);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
        }
        if (category?.[MAX]) {
            const validateMax = handleTotValidation(userTotData[MAX],PREPARE_TERMS_TAB.RRF_TERMS);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
        }
        if (category?.[RECUR_LIMIT]) {
            const validateRecurLimit = handleTotValidation(
                userTotData[RECUR_LIMIT],PREPARE_TERMS_TAB.RRF_TERMS
            );
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
        }
        if (category?.[DEBT_SYNDICATION]) {
            const validateDebtSyndication = handleTotValidation(userTotData[DEBT_SYNDICATION],PREPARE_TERMS_TAB.DS_TERMS);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
        }
        const tots = [];
        const recurLimitObj = getObjToSend(RECUR_LIMIT);
        recurLimitObj.tot_expiry_date = dayjs(new Date()).add(30, "day").format("YYYY-MM-DD");
        const standardObj = getObjToSend(STANDARD);
        standardObj.tot_expiry_date = dayjs(new Date()).add(30, "day").format("YYYY-MM-DD");
        const maxObj = getObjToSend(MAX);
        maxObj.tot_expiry_date = dayjs(new Date()).add(7, "day").format("YYYY-MM-DD");
        const debtSyndicationObj = getObjToSend(DEBT_SYNDICATION);
        let validateRecurLimit, validateStandard, validateMax, validateDebtSyndication;
        if (category?.[STANDARD]) {
            validateStandard = handleValidation(STANDARD);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
            tots.push(standardObj);
        }
        if (category?.[MAX]) {
            validateMax = handleValidation(MAX);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
            if (showMaxLimit) tots.push(maxObj);
        }
        if (category?.[RECUR_LIMIT]) {
            validateRecurLimit = handleValidation(RECUR_LIMIT);
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
            tots.push(recurLimitObj);
        }
        if (category?.[DEBT_SYNDICATION]) {
            validateDebtSyndication = handleValidation(DEBT_SYNDICATION);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
            tots.push(debtSyndicationObj);
            setDetectChange(state => ({
                ...state,
                [DEBT_SYNDICATION]: false
            }))
        }
        const body = {
            baseTotUpdates:{
                "discount_expiry_date": userTotData[RECUR_LIMIT]?.discount_expiry_date,
                "showRoi": userTotData[RECUR_LIMIT]?.showRoi,
            },
            investee_organization_id: investeeOrgId,
            tots,
            capitalLimit: getLimitRoadmapPayload()
        }; 
        if (currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
            body.capitalLimit =  getLimitRoadmapPayload()
            body.stage_id = totData[RECUR_LIMIT]?.stage_id;
        } else {
            body.stage_id = totData[DEBT_SYNDICATION]?.stage_id;
        }        
        callApi("/admin/tot-history/addTot", "post", body).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                const updatedData = res.data;
                onCreateSuccess(isUpdateToBeCalled,tots,updatedData,clonedTotData);
            }
            else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
            setIsSaveClicked(false);
        });
    }
    const handleUserUpdateConfirmation = (data) =>{
        setShowTotUpdatePopUp(true);
        setModalMessaging(data.messaging)
       }
       const handleUserUpdateCancel = ()  =>{
           setShowTotUpdatePopUp(false);
           onCancel();
       }
       // Updating a Term
       const handleConfirmUpdate = () =>{
           setShowTotUpdatePopUp(false);
           onSave(true);
       }
    const callUpdateTotApi = (props) => {
        const { category, isDeletedInMax, isDeletedInStandard , adminUpdateOveride } = props ?? {};
        const onSuccess = () => {
            fetchTotData();
            fetchAvailableLimit();
            setIsModerating(false);
            dispatch(fetchInvesteeDetails(investeeOrgId));
        }

        if (category?.[RECUR_LIMIT]) {
            const validateRecurLimit = handleTotValidation(
                userTotData[RECUR_LIMIT]
            );
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
        }
        if (category?.[STANDARD]) {
            const validateStandard = handleTotValidation(userTotData[STANDARD]);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
        }
        if (category?.[MAX]) {
            const validateMax = handleTotValidation(userTotData[MAX]);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
        }
        if (category?.[DEBT_SYNDICATION]) {
            const validateDebtSyndication = handleTotValidation(userTotData[DEBT_SYNDICATION]);
            if (!validateDebtSyndication.isValid) {
            showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
            return;
            }
        }
        const tots = [];
        const recurLimitObj = getObjToSend(RECUR_LIMIT);
        recurLimitObj.stage_tot_id = totData[RECUR_LIMIT]?._id;
        const standardObj = getObjToSend(STANDARD);
        standardObj.stage_tot_id = totData[STANDARD]?._id;
        if (isDeletedInStandard) standardObj.is_deleted = true;
        const maxObj = getObjToSend(MAX);
        maxObj.stage_tot_id = totData[MAX]?._id;
        if (isDeletedInMax) maxObj.is_deleted = true;
        const debtSyndicationObj = getObjToSend(DEBT_SYNDICATION);
        debtSyndicationObj.stage_tot_id = totData[DEBT_SYNDICATION]?._id;
        let validateStandard, validateMax, validateRecurLimit, validateDebtSyndication;
        if (category?.[STANDARD]) {
            validateStandard = handleValidation(STANDARD);
            if (!validateStandard.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateStandard.message);
                return;
            }
            tots.push(standardObj);
        }
        if (category?.[MAX]) {
            validateMax = handleValidation(MAX);
            if (!validateMax.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateMax.message);
                return;
            }
            tots.push(maxObj);
        }
        if (category?.[RECUR_LIMIT]) {
            validateRecurLimit = handleValidation(RECUR_LIMIT);
            if (!validateRecurLimit.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateRecurLimit.message);
                return;
            }
            tots.push(recurLimitObj);
        }
        if (category?.[DEBT_SYNDICATION]) {
            validateDebtSyndication = handleValidation(DEBT_SYNDICATION);
            if (!validateDebtSyndication.isValid) {
                showNotification(SHOW_NOTIFICATION.ERROR, validateDebtSyndication.message);
                return;
            }
            tots.push(debtSyndicationObj);
            setDetectChange(state => ({
                ...state,
                [DEBT_SYNDICATION]: false
            }))
        }
        const payload = {
            baseTotUpdates:{
                "discount_expiry_date": userTotData[RECUR_LIMIT]?.discount_expiry_date,
                "showRoi": userTotData[RECUR_LIMIT]?.showRoi,
            },
            tots: tots?.filter((tot) => {
                return tot._id
            }),
        }
        if (currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS) {
            payload.capitalLimit =  getLimitRoadmapPayload()
            payload.stage_id = totData[RECUR_LIMIT]?.stage_id ?? totData[STANDARD]?.stage_id;
            payload.allowUpdateAnyway = adminUpdateOveride;
        } else {
            payload.stage_id = totData[DEBT_SYNDICATION]?.stage_id;
        }
        callApi("admin/tot-history/tot-history-update", "post", payload).then(res => {
            if (res.status === API_STATUS.SUCCESS) {
                if(res.data?.messaging?.showModal && !adminUpdateOveride){
                    handleUserUpdateConfirmation(res.data)
                    return;
                }
                onSuccess();
            }
            else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION.ERROR, message);
            }
            setIsSaveClicked(false);
        });
    }

    const getLimitRoadmapPayload = () => {
        return ({
            orgId: investeeOrgId,
            baseTotId: totId,
            ...limitRoadmapData,
            tranches: limitRoadmapData.tranches.map((tranch) => ({
                ...tranch,
                tenure: tranch?.tenure?.split(" ")?.[0] || ''
            }))
        })
    }

    const isLimitRoadmapDataValid = () => {
        const limitRoadmap = Number(limitRoadmapData?.limitRoadmap);
        const tranches = limitRoadmapData?.tranches;
        let sum = 0;
        for(let i=0;i<tranches?.length;i++) {
            let tranch = tranches[i];
            sum += Number(tranch?.amount) || 0;
            if(!tranch.tenure) {
                showNotification(SHOW_NOTIFICATION.ERROR, `Please select timeline for Tranche ${i+2}`);
                return false;
            }
            if(!tranch.amount) {
                showNotification(SHOW_NOTIFICATION.ERROR, `Please enter amount for Tranche ${i+2}`);
                return false;
            }
            if(!tranch.comments) {
                showNotification(SHOW_NOTIFICATION.ERROR, `Please enter condition to unlock for Tranche ${i+2}`);
                return false;
            }
        }

        if (showMaxLimit) {
            const tradingLimit = Number(userTotData[MAX]?.tradingLimit) || 0
            if (limitRoadmap <= tradingLimit) {
                showNotification(SHOW_NOTIFICATION.ERROR, "Limit roadmap value must be greater than max trading limit");
                return false;
            }
            if (limitRoadmap !== (tradingLimit + sum)) {
                showNotification(SHOW_NOTIFICATION.ERROR, "Limit roadmap value must be equal to sum of tranches and max trading limit");
                return false;
            }
        } else if (showStandardLimit) {
            const tradingLimit = Number(userTotData[STANDARD]?.tradingLimit) || 0
            if (limitRoadmap <= tradingLimit) {
                showNotification(SHOW_NOTIFICATION.ERROR, "Limit roadmap value must be greater than max trading limit");
                return false;
            }
            if (limitRoadmap !== (tradingLimit + sum)) {
                showNotification(SHOW_NOTIFICATION.ERROR, "Limit roadmap value must be equal to sum of tranches and standard trading limit");
                return false;
            }
        }
        return true;
    }

    const onLimitRoadmapChange = () => {
        if(showMaxLimit) setDetectChange((state) => ({
            ...state,
            [MAX]: true,
            [STANDARD]: true
        }))
        else if(showStandardLimit) setDetectChange((state) => ({
            ...state,
            [STANDARD]: true
        }))
    }

    const renderButtons = () => (
        <ShowButtons
            identifierId={addOnId}
            status = {status}
            setLastEmail={setLastEmail}
            currentStep={currentStep}
            path={moderateRow?.due_date}
            totData={totData}
            totId={totData.baseTotId}
            totStatus={totData?.base_tot_status}
            setTotData={setTotData}
            isModerating={isModerating}
            setIsModerating={setIsModerating}
            detectChange = {detectChange}
            isSaveClicked={isSaveClicked}
            onCancel={onCancel}
            onSave={()=>{onSave();}}
            fetchTotData={fetchTotData}
            fetchMonthlyStatusInfo={fetchMonthlyStatusInfo}
            rrfTermToggle={rrfTermToggle}
            debtSyndicationToggle={debtSyndicationToggle}
            isExpired={isExpired}
            isWaitListed={isWaitListed}
        />
    );

    const renderStatus = () => (
        <RenderStatus
            status={status}
            setStatus={setStatus}
            statusOptions={statusOptions}
            isModerating={isModerating}
            isExpired={isExpired}
        />
    );

    const getTotData = () => {
        const result = {};
        if (userTotData) {
            result[RECUR_LIMIT] = getObjToSend(RECUR_LIMIT);
            result[STANDARD] = getObjToSend(STANDARD);
            result[MAX] = getObjToSend(MAX);
        }
        return result;
    }

    const memoizedTotData = useMemo(() => getTotData(), [userTotData, standardCP, standardCS, maxCP, maxCS, recurLimitCP, recurLimitCS, note1, note2, note3]);

    const renderTextBox = (state, setState, disabled, category, field) => (
        <RenderTextBox
            state={state}
            setState={setState}
            detectChange={detectChange}
            setDetectChange={setDetectChange}
            category={category}
            disabled={disabled}
            isModerating={isModerating}
            field={field}
            totData={memoizedTotData}
        />
    );

    const addMore = (state, setState, disabled) => {
        if (disabled) return;
        const arr = [...state];
        arr.push("");
        setState(arr);
    };

    const createProps = (cp, setCp, cs, setCs, note, setNote, suffix) => ({
        cp,
        setCp,
        cs,
        setCs,
        [`renderCP${suffix}`]: (disabled, category) => renderTextBox(cp, setCp, disabled, category, 'cp'),
        [`renderCS${suffix}`]: (disabled, category) => renderTextBox(cs, setCs, disabled, category, 'cs'),
        [`renderNote${suffix}`]: (disabled, category) => renderTextBox(note, setNote, disabled, category, 'note'),
        [`addMoreCP${suffix}`]: (disabled) => {
            addMore(cp, setCp, disabled);
        },
        [`addMoreCS${suffix}`]: (disabled) => {
            addMore(cs, setCs, disabled);
        },
    });
    
    const standardProps = createProps(standardCP, setStandardCP, standardCS, setStandardCS, note1, setNote1, '1');
    const maxProps = createProps(maxCP, setMaxCP, maxCS, setMaxCS, note2, setNote2, '2');
    const recurLimitProps = createProps(recurLimitCP, setRecurLimitCP, recurLimitCS, setRecurLimitCS, note3, setNote3, '3');

    return (
        <div className="modal fade" id="Moderation-Comment" role="dialog" style={{ overflowY: 'auto' }}>
            <div className="modal-dialog">
                <div className="modal-content shadowBorder" >
                    <div className="modal-header pb-0 d-flex">
                    {showTotUpdatePopUp && (
                            <ConfirmTotUpdate
                                show={modalMessaging.showModal}
                                onHide={handleUserUpdateCancel}
                                header={modalMessaging.header}
                                title={
                                    <>
                                    Changing terms at this stage will{" "}
                                    <span className="Bold">{modalMessaging.changingTermsMessage}</span> about this
                                    change.
                                    </>
                                }
                                onDelete={handleConfirmUpdate}
                                showMessage2 = {modalMessaging.showSharedMfaSlMessge}
                            />
                        )}
                        <StepperTabs
                           Steps={STEPS}
                           setCurrentStep={setCurrentStep}
                           currentStep={currentStep}
                           rrfTermToggle={rrfTermToggle}
                           setRrfTermToggle={setRrfTermToggle}
                           debtSyndicationToggle={debtSyndicationToggle}
                           setDebtSyndicationToggle={setDebtSyndicationToggle}
                           id={addOnId}
                           isAddOn={true}
                           toggleState={toggleState}
                           />
                        <button type="button" className="close" data-dismiss="modal">
                            <img src="../../../../../assets/modal-cross.svg" alt=""></img>
                        </button>
                    </div>
                    <div className="modal-body pb-4 pt-0">
                        <div className='row m-0'>
                            <div style={{ minWidth: "25%", maxWidth: "35%" }}>
                                <div style={{overflowY:'auto'}} className='d-flex flex-column justify-content-between h-100 mt-3'>
                                    {/* <Stepper
                                        steps={[
                                            {
                                                label: STEPS[0],
                                                check: (moderateRow.status === APPROVED || moderateRow.status === SPARK_APPROVED),
                                            },
                                            {
                                                label: STEPS[1]
                                            }
                                        ]}
                                        currentStep={currentStep}
                                        setCurrentStep={setCurrentStep}
                                        currentStepRef={currentStepRef}
                                    /> */}
                                    <ModeratorDetails
                                        identifierType="tot_add_on"
                                        addOnId={moderateRow?._id}
                                        investeeOrgId={investeeOrgId}
                                    />
                                    {/* <DataVaultUnderwriting investeeOrganization={investeeOrgData} /> */}
                                    <DealEvaluation
                                        identifierId={addOnId}
                                        currentStep={currentStep}
                                        status={status}
                                        note1={note1}
                                        orgId={investeeOrgId}
                                        monthlyData={monthlyData}
                                        moderateRow={moderateRow}
                                        setRrfTermToggle={setRrfTermToggle}
                                        rrfTermToggle={rrfTermToggle}
                                        debtSyndicationToggle={debtSyndicationToggle}
                                        currentModerateTab={currentModerateTab}
                                        setDealReportState={setDealReportState}
                                        dealReportState={dealReportState}
                                    /> 
                                </div>
                            </div>
                            <div style={{ width:'65%' }}>
                                {currentStep === 1 && (
                                    <ReviewFinancialData
                                        renderFields={(
                                            <div>
                                                <div className='light-round-border p-4'>
                                                    {Object.keys(statusInfo).map(item => (
                                                        <RenderSingleCategory
                                                            key={item}
                                                            keyName={item}
                                                            statusInfo={statusInfo}
                                                            setStatusInfo={setStatusInfo}
                                                            isModerating={isModerating}
                                                        />
                                                    ))}
                                                    <DetailsInput
                                                        disabled={!isModerating}
                                                        comment={comment}
                                                        handleChange={(value) => setComment(value)}
                                                    />
                                                </div>
                                                <AdditionalInfo
                                                    isModerating={isModerating}
                                                    addnInfo={addnInfo}
                                                    setAddnInfo={setAddnInfo}
                                                    setIsAddnInfo={setIsAddnInfo}
                                                    renderStatus={renderStatus}
                                                />
                                            </div>
                                        )}
                                    />
                                )}
                                {(currentStep === 2 || currentStep === 3)&& (
                                    <>
                                        <PrepareTerms
                                            standardProps={standardProps}
                                            maxProps={maxProps}
                                            recurLimitProps={recurLimitProps}
                                            isModerating={isModerating}
                                            renderNote4={(disabled, category) => renderTextBox(note4, setNote4, disabled, category)}
                                            totData={userTotData}
                                            setTotData={setUserTotData}
                                            initialTotData={initialUserTotData}
                                            showMaxLimit={showMaxLimit}
                                            setShowMaxLimit={setShowMaxLimit}
                                            showStandardLimit={showStandardLimit}
                                            setShowStandardLimit={setShowStandardLimit}
                                            detectChange={detectChange}
                                            setDetectChange={setDetectChange}
                                            userNameForCoborrower={userNameForCoborrower}
                                            setUserNameForCoborrower={setUserNameForCoborrower}
                                            originalTotData={totData}
                                            totalAvailableLimit={totalAvailableLimit}
                                            updateToDefaultUserTotData={updateToDefaultUserTotData}
                                            isTotAccepted={isTotAccepted}
                                            setCurrentModerateTab={setCurrentModerateTab}
                                            moratDropdownList={moratDropdownList}
                                            currentModerateTab={currentModerateTab}
                                            investeeOnboardedId={investeeOnboardedId}
                                            addOnId={addOnId}
                                            isAddOn={true}
                                        />
                                        {((showStandardLimit ||  showMaxLimit) && currentModerateTab === PREPARE_TERMS_TAB.RRF_TERMS)? (
                                            <LimitRoadmap 
                                            limitRoadmapData={limitRoadmapData} 
                                            setLimitRoadmapData={setLimitRoadmapData}
                                            onLimitRoadmapChange={onLimitRoadmapChange}
                                            isModerating={isModerating}
                                            isTotAccepted={isTotAccepted}
                                            />
                                        ): null}
                                    </>
    
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <MultipleTotFooter
                            renderButtons={() => (
                                <>
                                    {currentStep === 1 && (
                                        <span>{renderStatus()}</span>
                                    )}
                                    {renderButtons()}
                                </>
                            )}
                            lastEmail={currentStep === 1 ? lastEmail : totData?.emailSentDate}
                            // show expiry toggle only if its the newest add-on 
                            showToggle={(monthlyData[0]._id === moderateRow._id) && moderateRow.due_date !== "Onboarding" && currentStep === 2}
                            isExpired={isExpired}
                            setIsExpired={setIsExpired}
                            applicationId={moderateRow._id}
                            applicationType={APPLICATION_TYPE.ADD_ON}
                            fetchMonthlyData={fetchMonthlyData}
                            isDrawdownTaken={moderateRow.total_drawn > 0}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    );
}
