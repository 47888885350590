import { Drawer } from "@material-ui/core";
import styles from "./styles/RepaymentEditModal.module.scss";
import { get } from "lodash";
import {
  bulkUpdateOrderTransactionsHelper,
  getOrderAndOrdertransactionsByDrawdownId,
} from "../../../../../../../../../common/ApiHelpers";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { showNotification } from "../../../../../../../../../util/utility";
import { ORDER_STATUS } from "../../../../../../../../../common/ENUM";
import { SHOW_NOTIFICATION_STATUS } from "../../../../../../../../../constants/enums";

function RepaymentEditModal({
  showModal,
  setShowModal,
  investee_organization_id,
  setVerifiedDrawdowns,
}) {
  const [repayments, setRepayments] = useState([]);
  const [orderData, setOrderData] = useState({});
  const repaymentInitial = useRef(null);
  const orderInitial = useRef(null);
  useEffect(() => {
    if (showModal !== "NOTSHOW") fetchRepayments();
  }, [showModal]);

  const fetchRepayments = async () => {
    try {
      const repaymentsResponse = await getOrderAndOrdertransactionsByDrawdownId(
        showModal
      );
      if (repaymentsResponse) {
        const repayments = get(
          repaymentsResponse,
          "data.orderTransactions",
          []
        );
        const orderData = get(repaymentsResponse, "data.investorOrder", {});
        if(orderData?.isVerifiedAdmin) {
          setVerifiedDrawdowns(prevState => {
            if (!prevState.includes(orderData.drawdown_id)) {
              return [...prevState, orderData.drawdown_id];
            }
            return prevState;
          });
        }
        repaymentInitial.current = JSON.parse(JSON.stringify(repayments));
        orderInitial.current = JSON.parse(JSON.stringify(orderData));
        setRepayments(repayments);
        setOrderData(orderData);
      }
    } catch (error) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.ERROR,
        "Failed to fetch repayment schedule"
      );
    }
  };
  const onChangeDrawdownDate = (value) => {
    let newOrderData = { ...orderData };
    if (orderData.approval_date) {
      newOrderData.approval_date = moment(value).format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      );
    }
    if (orderData.freeze_date) {
      newOrderData.freeze_date = moment(value).format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      );
    }
    setOrderData(newOrderData);
  };
  const onChangeRepayment = (index, key, value) => {
    const newRepayments = [...repayments];
    newRepayments[index] = {
      ...newRepayments[index],
      [key]:
        key === "transaction_amount" ||
        key === "interest_earned" ||
        key === "fee"
          ? value ?? 0
          : value,
    };
    setRepayments(newRepayments);
  };
  const onDelete = (index) => {
    let newRepayments = [...repayments];
    if (repayments[index]._id) {
      newRepayments[index].is_deleted = !newRepayments[index].is_deleted;
    } else {
      newRepayments = repayments.filter(
        (repayment, repaymentIndex) => repaymentIndex !== index
      );
    }
    setRepayments(newRepayments);
  };
  const addRepaymentEntry = () => {
    let sampleRepayment = JSON.parse(
      JSON.stringify(repayments[repayments.length - 1])
    );
    sampleRepayment._id = undefined;
    setRepayments([
      ...repayments,
      {
        ...sampleRepayment,
        transaction_date: moment(sampleRepayment.transaction_date)
          .add(1, "month")
          .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
        transaction_amount: sampleRepayment.transaction_amount,
        interest_earned: 0,
        fee: 0,
        is_deleted: false,
        payment_number: repayments.length + 1,
        created_at: moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
        updated_at: moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      },
    ]);
  };

  const regenAndVerify = async () => {
    let { isChanged, changedRepayments, orderChanged } =
      checkIfRepaymentChanged();
    let orderDataToSend = null;
    if (!validateRepayment()) {
      showNotification(
        "error",
        "Please fill all the fields in the repayment schedule"
      );
      return;
    }
    if (orderChanged) {
      orderDataToSend = orderData;
    }

    const response = await bulkUpdateOrderTransactionsHelper(
      changedRepayments,
      orderDataToSend,
      showModal,
      investee_organization_id
    );
    if (response) {
      fetchRepayments();
      showNotification("success", "Repayment Schedule Updated Successfully");
    } else {
      showNotification("error", "Failed to update repayment schedule");
    }
  };

  const checkIfRepaymentChanged = () => {
    let isChanged = false;
    let changedRepayments = [];
    let orderChanged = false;

    repayments.forEach((repayment, index) => {
      if (
        !repayment._id ||
        repaymentInitial.current[index].transaction_date !==
          repayment.transaction_date ||
        repaymentInitial.current[index].transaction_amount !==
          repayment.transaction_amount ||
        repaymentInitial.current[index].interest_earned !==
          repayment.interest_earned ||
        repaymentInitial.current[index].fee !== repayment.fee ||
        repayment.is_deleted !== repaymentInitial.current[index].is_deleted
      ) {
        isChanged = true;
        repayment.transaction_amount = parseFloat(
          repayment.transaction_amount ?? "0"
        );
        repayment.interest_earned = parseFloat(
          repayment.interest_earned ?? "0"
        );
        repayment.fee = parseFloat(repayment.fee ?? "0");
        changedRepayments.push(repayment);
      }
    });

    if (
      orderData.approval_date !== orderInitial.current.approval_date ||
      orderData.freeze_date !== orderInitial.current.freeze_date
    ) {
      orderChanged = true;
    }
    return { isChanged, changedRepayments, orderChanged };
  };

  //validate the repayment schedule no value should be empty or zero
  const validateRepayment = () => {
    let isValid = true;
    repayments.forEach((repayment) => {
      const amount = parseFloat(repayment.transaction_amount);
      const interest = parseFloat(repayment.interest_earned);
      //adding check for repayment fee only for open orders
      if (!repayment.transaction_date || !repayment.transaction_amount || !repayment.interest_earned) {
        isValid = false;
      }
      if (amount <= 0 || interest < 0) {
        isValid = false;
      }
    });
    return isValid;
  };

  const showVaildateRepaymentButtonText = () => {
    if (orderData?.approval_status === ORDER_STATUS.APPROVED) {
      return "Regenerate & Verify";
    } else {
      return "Regenerate";
    }
  };
  return (
    <Drawer
      anchor={"right"}
      open={showModal !== "NOTSHOW"}
      onClose={() => setShowModal("NOTSHOW")}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: "50%",
          padding: "10px",
        },
      }}
      disableEnforceFocus
      className="ManageInvestorModal"
    >
      <div className={styles.container}>
        {/* Header with timestamp */}
        <div className={styles.header}>
          <h2 className={styles.title}>Repayment Schedule</h2>
        </div>
        {/* Top Fields Grid */}
        <div className={styles.topFields}>
          <div className={styles.fieldGroup}>
            <label className={styles.label}>Amount</label>
            <input
              type="text"
              value={orderData.total_investment}
              className={styles.topInput}
              disabled
            />
          </div>

          <div className={styles.fieldGroup}>
            <label className={styles.label}>Tenure (months)</label>
            <input
              type="text"
              value={orderData.noOfRepayments}
              className={styles.topInput}
              disabled
            />
          </div>

          <div className={styles.fieldGroup}>
            <label className={styles.label}>Drawdown Date</label>
            <input
              type="date"
              value={
                orderData.approval_date
                  ? orderData.approval_date?.split("T")[0]
                  : orderData.freeze_date?.split("T")[0]
              }
              className={styles.topInput}
              disabled={orderData.approval_status !== ORDER_STATUS.APPROVED}
              onChange={(e) => onChangeDrawdownDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className={styles.fieldGroup}>
            <label className={styles.label}>ROI(%)</label>
            <input
              type="text"
              value={orderData.order_roi}
              className={styles.topInput}
              disabled
            />
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.contentWrapper}>
            {/* Header */}
            <div className={styles.headerGrid}>
              <div className={styles.headerCell}>Repayment</div>
              <div className={styles.headerCell}>Date</div>
              <div className={styles.headerCell}>Amount</div>
              <div className={styles.headerCell}>Interest</div>
              <div className={styles.headerCell}>Fee (Without GST)</div>
              <div className={styles.headerCell}></div>
            </div>

            {/* Rows */}
            <div className={styles.rowsWrapper}>
              {repayments?.map((repayment, index) => (
                <div
                  key={repayment._id || index}
                  className={`${styles.row} ${
                    repayment.is_deleted ? styles.deleted : ""
                  }`}
                >
                  <div>
                    <input
                      type="text"
                      value={index + 1}
                      disabled
                      className={styles.input}
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      value={repayment.transaction_date?.split("T")[0]}
                      onChange={(e) =>
                        onChangeRepayment(
                          index,
                          "transaction_date",
                          `${e.target.value}T00:00:00.000Z`
                        )
                      }
                      onKeyDown={(e) => e.preventDefault()}
                      className={styles.input}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      value={repayment.transaction_amount}
                      onChange={(e) =>
                        onChangeRepayment(
                          index,
                          "transaction_amount",
                          e.target.value
                        )
                      }
                      className={styles.input}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      value={repayment.interest_earned}
                      onChange={(e) =>
                        onChangeRepayment(
                          index,
                          "interest_earned",
                          e.target.value
                        )
                      }
                      className={styles.input}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      value={repayment.fee}
                      onChange={(e) =>
                        onChangeRepayment(index, "fee", e.target.value)
                      }
                      className={styles.input}
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => onDelete(index)}
                      className={styles.deleteButton}
                    >
                      ×
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Add Repayment Button */}
        <button
          className={styles.addRepaymentButton}
          onClick={addRepaymentEntry}
        >
          <span className={styles.plusIcon}>+</span> Add Repayment
        </button>

        {/* Regenerate & Verify Button */}
        <div className={styles.bottomActions}>
          <button className={styles.regenerateButton} onClick={regenAndVerify}>
            {orderInitial.current && showVaildateRepaymentButtonText()}
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default RepaymentEditModal;
