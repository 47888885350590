import React, { useEffect } from 'react'
import './InputCollectionMethod.module.scss'
import { isEmpty } from 'lodash';

interface CoborrowerDropdownsProps {
    userNameForCoborrower: any;
    selectedOptions: any;
    setSelectedOptions: (selectedOptions: any) => void;
    isDisabled: boolean;
    index: number;
    handleChange: (type: string, e: any) => void;
    item: any;
}

interface UserInfo {
    userId: string;
    name: string;
    email: string;
    dontShow?: boolean;
}

function CoborrowerDropdowns(props: CoborrowerDropdownsProps) {
    const { userNameForCoborrower, selectedOptions, setSelectedOptions, isDisabled, index, handleChange, item } = props;
    const INDIVIDUAL = "individual";
    const [coborrower, setCoborrower] = React.useState<any>({
        type: item.type ?? INDIVIDUAL,
        name: item['name'] ?? null,
        user_id: item['user_id'] ?? null,
        email: item['email'] ?? null
    });
    const [addName, setAddName] = React.useState<boolean>(false);
    const COMPANY = "company";
    const TYPE = "type";

    useEffect(() => {
        setCoborrower({
            type: item.type ?? INDIVIDUAL,
            name: item['name'] ?? '',
            user_id: item['user_id'] ?? '',
            email: item['email'] ?? ''
        })
    }, [item])

    useEffect(() => {
        if (coborrower.name && !coborrower.user_id) {
            setAddName(true)
        }
        else {
            setAddName(false)
        }
    }, [coborrower.user_id])

    const onchange = (key: string, value: string, freeText?: boolean) => {
        let obj = { ...coborrower };
        let selectedObj = { ...selectedOptions };
        if (key === 'name') {
            if (value === 'add') {
                setAddName(true)
            }
            else if (freeText) {
                obj = {
                    ...obj,
                    [key]: value,
                    user_id: null,
                    email: null
                }
            }
            else {
                const selectedUser = userNameForCoborrower?.find((item: UserInfo) => item?.name === value);
                obj = {
                    ...obj,
                    [key]: selectedUser?.name,
                    user_id: selectedUser?.userId,
                    email: selectedUser?.email  // Add email when selecting a user
                }
            }
        } else {
            obj = {
                ...obj,
                [key]: value
            }
        }
        const copy = [...selectedOptions.Coborrower];
        copy[index] = obj;
        setCoborrower(obj);
        selectedObj = {
            ...selectedObj,
            Coborrower: copy
        }
        setSelectedOptions(selectedObj);
        handleChange("collectionMethods", selectedObj);
    }

    const removeRCM = () => {
        const copy = [...selectedOptions.Coborrower];
        copy.splice(index, 1);
        const selectedObj = {
            ...selectedOptions,
            Coborrower: copy
        }
        setSelectedOptions(selectedObj);
        handleChange("collectionMethods", selectedObj);
    }

    // Helper function to format name and email
    const formatNameWithEmail = (name: string, email: string) => {
        return `${name} (${email})`;
    }

    return (
        <>
            <select name="coborrower"
                onChange={(e) =>
                    onchange('type', e?.target?.value)
                }
                className={'Selected'}
                disabled={isDisabled || coborrower.user_id}
                style={{ width: '30%', padding: '0 3px' }}
                value={coborrower.type}
            >
                <option value={COMPANY}>Company</option>
                <option value={INDIVIDUAL}>Individual</option>
            </select>
            {
                (coborrower?.[TYPE] === INDIVIDUAL && !addName) ?
                    <>
                        <select
                            id={`${item.name}`}
                            disabled={isDisabled || coborrower.user_id}
                            value={coborrower.name}
                            onChange={(e) => {
                                onchange('name', e?.target?.value)
                            }}>
                            {
                                isEmpty(coborrower?.name) ? 
                                    <option disabled value="">Select</option>
                                    : 
                                    <option value={coborrower?.name}>
                                        {coborrower?.email ? 
                                            formatNameWithEmail(coborrower?.name, coborrower?.email) 
                                            : coborrower?.name}
                                    </option>
                            }
                            {userNameForCoborrower && userNameForCoborrower.map((user: UserInfo) => (
                                <option
                                    key={user?.userId}
                                    style={user?.dontShow ? { display: 'none' } : {}}
                                    value={user?.name}
                                >
                                    {user?.email ?
                                        formatNameWithEmail(user?.name, user?.email)
                                        : user?.name}
                                </option>
                            ))}
                            <option value={'add'}>Add New Individual</option>
                        </select>
                    </>
                    :
                    <input
                        type='text'
                        id={`${item.name}`}
                        className={isDisabled ? `Selected DisabledSelected` : 'Selected'}
                        disabled={isDisabled}
                        value={coborrower.name}
                        onChange={(e) =>
                            onchange('name', e?.target?.value, true)
                        }
                    />
            }
            {<img style={{cursor:'pointer'}} src="/assets/remove.svg" alt={'coborrower'} onClick={removeRCM}/>}
        </>
    )
}

export default CoborrowerDropdowns